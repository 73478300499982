import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpParams } from '../shared/loader/custom-httpParam';

@Injectable()
export class AdminService {

	constructor(
		private httpClient: HttpClient,
		private translate: TranslateService
	) { }

	getLoggingInfo(options) {
		return this.httpClient.post('/admin/getLoggingInfo', options, {
			observe: "body"
		});
	}

	deleteLog(id, type) {
		return this.httpClient.post('/admin/deleteLog', {id, type}, {
			observe: "body"
		});
	}

	getErrorLog(errorId: number, errorType: string) {
		return this.httpClient.post('/admin/getErrorInfo', {id: errorId, logType: errorType}, {
			observe: "body"
		});
	}

	getCronJobsStatus() {
		return this.httpClient.post('/admin/getCronJobsStatus', {}, {
			observe: "body"
		});
	}

	getSchedFunctionsStatus() {
		return this.httpClient.post('/admin/getSchedFunctionsStatus', {}, {
			observe: "body"
		});
	}

	updateSchedFunctionsStatus(schedFunctions) {
		return this.httpClient.post('/admin/updateSchedFunctionsStatus', schedFunctions, {
			observe: "body"
		});
	}

	getPermission(permissionId, options?) {
		return this.httpClient.post('/admin/getPermission', {permissionId, options}, {
			observe: "body"
		});
	}

	updatePermission(permissionId, permission) {
		return this.httpClient.post('/admin/updatePermission', {permissionId, permission}, {
			observe: "body"
		});
	}

	getRejectedDeviceConnections(options) {
		return this.httpClient.post('/admin/getRejectedDeviceConnections', options, {
			observe: "body"
		});
	}

	getUnusedLogs() {
		return this.httpClient.get('/admin/getUnusedLogs');
	}

	deleteRejectionLog(id) {
		return this.httpClient.post('/admin/deleteRejectionLog',{id} , {
			observe: "body"
		});
	}

	getActionLogs(options) {
		return this.httpClient.post('/admin/getActionLogs', options, {
			observe: "body"
		});
	}

	getContactUs(options?) {
		return this.httpClient.post('/admin/getContactUs', {options}, {
			observe: "body"
		});
	}

	deleteContactUs(ids) {
		return this.httpClient.post('/admin/deleteContactUs', {ids}, {
			observe: "body"
		});
	}

	getUsersForAudit() {
		return this.httpClient.post('/admin/getUsersForAudit', {}, {
			observe: "body"
		});
	}

	getPermissionsForAudit() {
		return this.httpClient.post('/admin/getPermissionsForAudit', {}, {
			observe: "body"
		});
	}

	getRegistrationRequests(id?) {
		return this.httpClient.post('/user/getRegistrationRequests', {id}, {
			observe: "body"
		});
	}

	submitRegistrationRequest(registrationRequest) {
		return this.httpClient.post('/user/submitRegistrationRequest', {registrationRequest}, {
			observe: "body"
		});
	}

	forceWebsiteRefresh() {
		return this.httpClient.post('/admin/forceWebsiteRefresh', {}, {
			observe: "body"
		});
	}

	addSchedToRun(sched: any) {
		return this.httpClient.post('/admin/addSchedToRun', { sched	}, {
			observe: "body"
		});
	}

	uploadFWFile(data) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');

		return this.httpClient.post('/upload/uploadFWFile', data, {
			observe: "body",
			headers,
		});
	}

	getAllSims() {
		return this.httpClient.get('/cellular/getAllSims', {
			observe: "body"
		})
	}

	getConnectivityVerificationLogs(reportId: number) {
		return this.httpClient.get('/admin/getConnectivityVerificationLogs', {
			observe: "body",
			params: {
				reportId
			}
		});
	}

	generateData(options: {deviceId: string, from: Date, to: Date, type: string, maxPerDay: number, withDailyDetails: boolean, withAnalytics: boolean}) {
		return this.httpClient.post('/admin/generateData', { options }, {
			observe: "body"
		})
	}

	getConnectivityVerificationStatistics() {
		return this.httpClient.get('/admin/getConnectivityVerificationStatistics', {
			observe: 'body'
		});
	}

	getDebugLogs(startLine=0, fileName=null) {
		return this.httpClient.post('/admin/getDebugLogs', {startLine, fileName}, {
			observe: "body",
			params: new CustomHttpParams({ noUIBlock: true })
		});
	}

	generateScript(command, macAddress, siteId, fileName, excludeLargeSites) {
		return this.httpClient.post('/admin/generateScript', {command, macAddress, siteId, fileName, excludeLargeSites}, {
			observe: "body"
		});
	}

	getCurrentAppVersionInfo() {
		return this.httpClient.get('/admin/getCurrentAppVersionInfo', {
			observe: "body"
		});
	}

	saveAppVersionSettings(appVersionSettings: { minium_app_version: string, latest_app_version: string, userId: number }) {
		return this.httpClient.post('/admin/saveAppVersionSettings', { appVersionSettings }, {
			observe: "body"
		});
	}
}
