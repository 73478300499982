<div class="mt-2">
	<button class="btn btn-primary" type="button" routerLink="/orders/add" *ngIf="userService.hasAccessFunction('create_orders')">
		{{'orders.create' | translate}} <fa-icon icon="plus"></fa-icon>
	</button>
</div>

<form #searchOrdersForm="ngForm" class="row" (ngSubmit)="getOrdersBySearchCriteria()">
	<div class="col-lg-6 col-md-8 col-sm-12 mt-4">
		<div class="my-2 col-8" *ngIf="selectedCriteria.field != SEARCH_CRITERIA.DATE">
			<input name="searchString" [(ngModel)]="selectedCriteria.value" trim-text placeholder="{{'g.search' | translate}}" class="form-control" required />
		</div>
		<div class="my-2 col-8 d-flex align-items-center " *ngIf="selectedCriteria.field == SEARCH_CRITERIA.DATE">
			<label class="form-label mr-2"><span>{{'g.from'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="selectedCriteria.startDate" placeholder="{{'g.search' | translate}}" class="form-control mr-2" required />
			<label class="form-label mr-2"><span>{{'g.to'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="selectedCriteria.endDate" placeholder="{{'g.search' | translate}}" class="form-control" required />
		</div>

		<div class="mt-3 input-group">
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="created_at" [(ngModel)]="selectedCriteria.field" (click)="selectCriteria('date')" id="created_at" [value]="SEARCH_CRITERIA.DATE">
				<label class="form-check-label" for="created_at">{{'g.date' | translate}}</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="po_number" [(ngModel)]="selectedCriteria.field" (click)="selectCriteria('po_number')" id="po_number" [value]="SEARCH_CRITERIA.PO_NUMBER">
				<label class="form-check-label" for="po_number">{{'orders.po_number' | translate}}</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="receiver_email" [(ngModel)]="selectedCriteria.field" (click)="selectCriteria('receiver_email')" id="receiver_email" [value]="SEARCH_CRITERIA.RECEIVER_EMAIL">
				<label class="form-check-label" for="receiver_email">{{'orders.receiver_email_search' | translate}}</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="receiver_name" [(ngModel)]="selectedCriteria.field" (click)="selectCriteria('receiver_name')" id="receiver_name" [value]="SEARCH_CRITERIA.RECEIVER_NAME">
				<label class="form-check-label" for="receiver_name">{{'orders.receiver_name' | translate}}</label>
			</div>
			<div class="form-check form-check-inline">
				<input class="form-check-input" type="radio" name="receiver_company_name" [(ngModel)]="selectedCriteria.field" (click)="selectCriteria('receiver_company_name')" id="receiver_company_name" [value]="SEARCH_CRITERIA.RECEIVER_COMPANY_NAME">
				<label class="form-check-label" for="receiver_company_name">{{'orders.receiver_company_search' | translate}}</label>
			</div>
		</div>
		<div class="mt-3">
			<button type="submit" class="btn btn-primary pointer" [disabled]="shouldEnableSearchBtn(selectedCriteria)">{{'g.search' | translate}}</button>
			<button type="submit" class="btn btn-primary pointer ml-2" [disabled]="shouldEnableSearchBtn(selectedCriteria)" (click)="resetFormData()" *ngIf="searchApplied">
				{{'g.clear_search' | translate}}
			</button>
		</div>
	</div>
</form>

<div class="d-flex justify-content-end mt-1 ml-2">
	<button class="btn btn-primary pointer" (click)="exportToCsvFile()">{{'g.export_to_csv' | translate}}</button>
</div>

<div class="mt-5 d-flex justify-content-between">
	<h2>{{'orders.title' | translate}}</h2>
	<div class="d-flex">
		<ng-select
			class="mr-2"
			[ngStyle]="{'width': '250px'}"
			[(ngModel)]="filterByDeviceType"
			[items]="filterOrdersByDeviceTypes"
			bindLabel="label"
			bindValue="id"
			[multiple]="true"
			(ngModelChange)="getOrders({currentPage: 1})"
			name="device_filter_type"
			placeholder="{{'orders.filter_by_device_type' | translate}}"
		></ng-select>

		<ng-select
			*ngIf="activeTable != 'shipped'"
			[ngStyle]="{'width': '250px'}"
			[(ngModel)]="sippingStatus"
			[items]="filterBySippingStatus"
			bindLabel="label"
			bindValue="id"
			(ngModelChange)="getOrders({currentPage: 1})"
			name="warning_filter"
			placeholder="{{'orders.filter_by_sipping_status' | translate}}"
		></ng-select>
	</div>
</div>

<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
	<ul class="navbar-nav mr-auto">
		<li class="nav-item">
			<a class="nav-link p-3 light-grey small pointer"
				[ngClass]="{'active': activeTable == 'unshipped'}"
				(click)="setActiveTable('unshipped')"
			>
				<h5> {{'orders.unshipped' | translate}}</h5>
			</a>
		</li>
		<li class="nav-item">
			<a class="nav-link p-3 light-grey small pointer"
				[ngClass]="{'active': activeTable == 'shipped'}"
				(click)="setActiveTable('shipped')"
			>
				<h5> {{'orders.shipped' | translate}}</h5>
			</a>
		</li>
	</ul>
</nav>

<div class="mt-4 mb-2 ml-2 mr-2">
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getOrders($event)"
	></sct-custom-table>
</div>

<mat-menu #orderMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-order="order">
		<a mat-menu-item [routerLink]="['/orders/edit', order.id]" *ngIf="showEditOrderOption(order.status)">{{'g.edit' | translate}}</a>
		<span mat-menu-item class="pointer" *ngIf="showAddTrackingNumberOption(order.status)" (click)="trackingNumberModal.show(); addTrackingNumberOrderId = order.id">{{'orders.add_tracking_number' | translate}}</span>
		<span mat-menu-item class="pointer" *ngIf="showDeleteOrder(order.status)" (click)="deleteNewOrderModal.show(); selectedOrderToDelete = order;">{{'g.delete' |translate}}</span>
	</ng-template>
</mat-menu>

<div class="w-100">
	<server-pagination [idField]="idField" [limit]="ordersCountLimit" (paginate)="getOrders($event)" #serverPagination>
	</server-pagination>
</div>

<app-modal #trackingNumberModal size="lg" title="{{'ready_to_ship.set_tracking_number' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-4">
				<strong>{{'ready_to_ship.tracking_number' | translate}} *</strong>
			</div>
			<div class="col-md-8">
				<input max="200" type="text" placeholder="{{'ready_to_ship.tracking_number' | translate}}" class="form-control"
					[ngClass]="{'border border-danger': trackingNumberError}" name="tracking_number"
					[(ngModel)]="trackingNumber" (ngModelChange)="trackingNumberError=''">
				<p class="text-danger" *ngIf="trackingNumberError"><small>{{trackingNumberError}}</small></p>
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-4">
				<strong>{{'orders.shipping_company' | translate}} *</strong>
			</div>
			<div class="col-md-8">
				<input max="255" type="text" placeholder="{{'orders.shipping_company' | translate}}" class="form-control"
					[ngClass]="{'border border-danger': shippingCompanyError}" name="shipping_company"
					[(ngModel)]="shippingCompany" (ngModelChange)="shippingCompanyError=''">
				<p class="text-danger" *ngIf="shippingCompanyError"><small>{{shippingCompanyError}}</small></p>
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-4">
				<strong>{{'orders.calc_shipping_date' |translate}}</strong>
			</div>
			<div class="col-md-8">
				<input class="form-control" placeholder="mm/dd/yyyy" name="shipping_date" [(ngModel)]="shippingDate" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
			</div>
		</div>

		<div class="modal-footer mt-4">
			<button class="btn btn-default mr-2" (click)="resetAddTrackingModal()">{{'g.cancel' | translate}}</button>
			<button class="btn btn-primary" (click)="addTrackingNumber()">{{'g.ok' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal #downloadFilesDialog
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	[size]="'lg'"
	[disableConfirm]="getDownloadButtonStatus()"
	>
	<div dialog-content>
		<div class="px-3 pb-3">
			<div class="raw">
				<h5>{{ 'orders.order_attachment' | translate }}</h5>
				<div *ngFor="let index of orderAttachedFiles | keys">
					<div class="form-check d-flex justify-content-between">
						<span>
							<input class="form-check-input" type="checkbox" [checked]="selectedFilesToDownload[orderAttachedFiles[index].file_name+'%%0']" id="{{index}}-o" (change)="selectFile($event, orderAttachedFiles[index])">
							<label class="form-check-label" for="{{index}}-o">
								{{orderAttachedFiles[index].file_name}}
							</label>
						</span>
						<a *ngIf="userService.hasAccessFunction('create_orders')" title="{{'g.delete' | translate}}" class="text-danger ml-1" style="cursor: pointer;" (click)="showDeleteAttachmentModel(orderAttachedFiles[index].id)"><i class="fa fa-trash"></i></a>
					</div>
				</div>
			</div>
			<div *ngIf="!(orderAttachedFiles | keys).length" class="raw">
				<span>{{'attachments.no_attachments' | translate }}</span>
			</div>
			<div *ngIf="(shippingAttachedFiles | keys).length" class="raw">
				<hr>
				<h5>{{ 'orders.shipment_attachments' | translate }}</h5>
				<div *ngFor="let row of shippingAttachedFiles | keyvalue">
					<b>{{'attachments.shipment_no_attachments' | translate:{"no": row.value.counter} }}</b>
					<div *ngIf="(row.value.attached_files | keys).length" class="raw">
						<div *ngFor="let key of row.value.attached_files | keys" class="form-check">
							<input class="form-check-input" type="checkbox" [checked]="selectedFilesToDownload[row.value.attached_files[key]+'%%1']" id="{{key}}-s" (change)="selectFile($event, { file: row.value.attached_files[key], id: row.key }, true)">
							<label class="form-check-label" for="{{key}}-s">
								{{row.value.attached_files[key]}}
							</label>
						</div>
						<hr *ngIf="!row.value.is_last_shipment">
					</div>
					<div *ngIf="!(row.value.attached_files | keys).length" class="raw">
						<span>{{'attachments.no_attachments' | translate }}</span>
						<hr *ngIf="!row.value.is_last_shipment">
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer" dialog-footer>
			<button *ngIf="userService.hasAccessFunction('create_orders')" class="btn btn-light" (click)="downloadFilesDialog.hide(); openAddDialog(selectedHistory)">
				<fa-icon icon="plus" size="1x" class="pointer text-success ms-3" title="attachments"></fa-icon>
				{{ 'g.add' | translate }}
			</button>
			<button type="submit" class="btn btn-primary" (click)="downloadFilesDialog.onConfirm()" [disabled]="getDownloadButtonStatus()">{{'ready_to_ship.download' | translate}}</button>
			<button type="button" class="btn btn-light" (click)="downloadFilesDialog.onHide()">{{'g.cancel' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal #addAttachmentsDialog
	[title]="'attachments.attachments' | translate"
	[hasConfirmButton]="false"
	[hasCloseButton]="false"
	[size]="'lg'"
	>
	<div dialog-content>
		<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
			<div *ngIf="showValidationErrors()" class="alert alert-danger" role="alert">
				<h6 class="alert-heading">{{'g.errors' | translate}}</h6>
				<ul>
					<li *ngIf="selectedFilesValidations.min_count">{{'attachments.files_min_count_err' | translate}}</li>
					<li *ngIf="selectedFilesValidations.max_count">{{'attachments.files_max_count_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFilesCount}})</li>
					<li *ngIf="selectedFilesValidations.extension">{{'attachments.extension_err' | translate}}: ({{attachmentsService.allowedUploadFilesExt.join(', ')}})</li>
					<li *ngIf="selectedFilesValidations.size">{{'attachments.size_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFileSize / attachmentsService.mbBytes}} MB)</li>
					<li *ngIf="selectedFilesValidations.note">{{'attachments.note_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxNoteCharacters}})</li>
				</ul>
			</div>
			<div class="row mb-2">
				<label class="col-md-4 col-form-label">
					{{'attachments.choose_files' | translate}}
				</label>
				<div class="col-md-6">
					<div class="custom-file">
						<input #uploadAttachmentsBtn class="form-control" id="customFile" type="file" [accept]="attachmentsService.allowedUploadFilesExt.join(', ')" id="actual-btn" name="files" multiple (change)="selectFiles($event)">
					</div>
				</div>
			</div>

			<div class="mt-5 d-flex flex-row-reverse">
				<button class="btn btn-light" (click)="addAttachmentsDialog.hide()">{{'g.close' | translate}}</button>
				<button class="btn btn-primary me-2" (click)="addAttachments()" [disabled]="!selectedFiles.length">{{'g.submit' | translate}}</button>
			</div>
		</form>
	</div>
</app-modal>

<app-modal #successAttachmentsDialog
	[title]="'attachments.success_attachments' | translate"
	[hasConfirmButton]="false"
	[hasCloseButton]="false"
	[size]="'lg'"
	>
	<div dialog-content>
		<table class="table table-striped">
			<thead>
				<th>{{'g.file_name' | translate}}</th>
				<th>{{'g.status' | translate}}</th>
			</thead>
			<tbody>
				<tr *ngFor="let file of selectedFiles">
					<td>{{file.name}}</td>
					<td *ngIf="!successAttachments[file.name] && !filesAlreadyUploaded[file.name]">
						<div class="spinner-border spinner-border-sm text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</td>
					<td *ngIf="successAttachments[file.name]" class="text-success"><i class="fa fa-check"></i></td>
					<td *ngIf="filesAlreadyUploaded[file.name]" class="text-danger">{{'attachments.file_already_attached' | translate}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-modal>

<app-modal #deleteAttachmentsModal title="{{'g.delete' | translate}} {{'attachments.attachments' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
		<p>{{'attachments.delete_attachment' | translate}}</p>
	</form>
	<div class="mt-2 d-flex justify-content-end">
		<button class="btn btn-danger" (click)="deleteAttachment()">{{'g.delete' | translate}}</button>
		<button class="btn btn-light ml-2" (click)="deleteAttachmentsModal.hide()">{{'g.close' | translate}}</button>
	</div>
</app-modal>

<app-modal #deleteNewOrderModal title="{{'g.confirm_action' | translate}}" closeBtnText="{{'g.no' | translate}}" confirmBtnText="{{'g.yes' | translate}}">
	<div>
		<p>{{'orders.delete_new_order_message' |translate:{"po_number": selectedOrderToDelete.po_number} }}</p>
	</div>
</app-modal>
