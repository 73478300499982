import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgmCoreModule } from '@agm/core';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { TranslateFileLoader } from './TranslateFileLoader';
import { LoaderService } from './shared/loader/loader.service';
import { HeaderService } from './shared/header/header.service';
import { LoaderInterceptorService } from './shared/loader/loader-interceptor.service';
import { NotificationMessageService } from './shared/notification-message/notification-message.service';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoginComponent } from './users/login/login.component';
import { ForgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { CaptchaComponent } from './users/captcha/captcha.component';
import { UsersComponent } from './users/users.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { RegisterComponent } from './users/register/register.component';
import { ConfirmUserEmailComponent } from './users/confirm-user-email/confirm-user-email.component';
import { RegistrationRequestsComponent } from './admin/registration-requests/registration-requests.component';
import { ValidateRegistrationRequestsComponent } from './admin/validate-registration-requests/validate-registration-requests.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerEditPageComponent } from './customers/customer-edit-page/customer-edit-page.component';
import { HomeComponent } from './home/home.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { EnterpriseEditComponent } from './enterprise/enterprise-edit/enterprise-edit.component';
import { SitesComponent } from './sites/sites.component';
import { SiteEditPageComponent } from './sites/site-edit-page/site-edit-page.component';
import { CommonDataService } from './shared/services/common-data.service';
import { ValidationService } from './shared/services/validation.service';
import { NavigationService } from './shared/services/navigation.service';
import { CustomerInfoComponent } from './home/customer-dashboard/customer-info/customer-info.component';
import { SearchDevicesComponent } from './device/search-devices/search-devices.component';
import { ManageDevicePageComponent } from './device/manage-device-page/manage-device-page.component';
import { ReverseEventsComponent } from './device/reverse-events/reverse-events.component';
import { ReverseCalibrationComponent } from './device/reverse-calibration/reverse-calibration.component';
import { ReverseRtComponent } from './device/reverse-rt/reverse-rt.component';
import { AgGridModule } from 'ag-grid-angular';
import { ErrorLogComponent } from './admin/logs/error-log/error-log.component';
import { ViewErrorComponent } from './admin/logs/error-log/view-error/view-error.component';
import { SchedControlComponent } from './admin/sched/sched-control/sched-control.component';
import { SchedMonitorComponent } from './admin/sched/sched-monitor/sched-monitor.component';
import { AdminService } from './admin/admin.service';
import { SiteDashboardComponent } from './home/site-dashboard/site-dashboard.component';
import { SideMenuService } from './shared/side-menu/side-menu.service';
import { LeftPanelService } from './shared/left-panel/left-panel.service';
import { SiteInfoComponent } from './home/site-dashboard/site-info/site-info.component';
import { ValidateFormDirective } from './shared/directives/validate-form';
import { FirmwareUpdateComponent } from './management/firmware-update/firmware-update.component';
import { DeviceListingAdminComponent } from './admin/device/device-listing-admin/device-listing-admin.component';
import { DeviceEditPageComponent } from './admin/device/device-edit-page/device-edit-page.component';
import { simUsageManagementComponent } from './admin/sim-usage-management/sim-usage-management.component';
import { SiteEditComponent } from './sites/site-edit-page/site-edit/site-edit.component';
import { CustomerEditComponent } from './customers/customer-edit-page/customer-edit/customer-edit.component';
import { EditAccessFunctionsComponent } from './users/edit-access-functions/edit-access-functions.component';
import { RtComponent } from './home/site-dashboard/device/device-dashboard/rt/rt.component';
import { EventsComponent } from './home/site-dashboard/device/device-dashboard/events/events.component';
import { ConnectivityTrackComponent } from './home/site-dashboard/device/device-dashboard/connectivity-track/connectivity-track.component';
import { ConfigTrackComponent } from './home/site-dashboard/device/device-dashboard/config-track/config-track.component';
import { QuickViewComponent } from './home/site-dashboard/device/device-dashboard/quick-view/quick-view.component';
import { DeviceSettingsComponent } from './home/site-dashboard/device/device-dashboard/device-settings/device-settings.component';
import { SetAdcComponent } from './home/site-dashboard/device/device-dashboard/set-adc/set-adc.component';
import { GlobalRecordsComponent } from './home/site-dashboard/device/device-dashboard/global-records/global-records.component';
import { DebugRecordsComponent } from './home/site-dashboard/device/device-dashboard/debug-records/debug-records.component';
import { DeviceManagementService } from './device/device-management.service';
import { DeviceService } from './home/site-dashboard/device/device.service';
import { BreadcrumbService } from './shared/breadcrumb/breadcrumb.service';
import { PermissionEditComponent } from './admin/permission-edit/permission-edit.component';
import { DevicesListingComponent } from './home/site-dashboard/device/devices-listing/devices-listing.component';
import { DeviceDashboardComponent } from './home/site-dashboard/device/device-dashboard/device-dashboard.component';
import { DeviceSelectCriteriaComponent } from './home/site-dashboard/device/device-select-criteria/device-select-criteria.component';
import { SitesMapComponent } from './home/sites-map/sites-map.component';
import { DeviceActionsComponent } from './home/site-dashboard/device/device-dashboard/device-actions/device-actions.component';
import { AdminComponent } from './admin/admin.component';
import { ZombieSitesComponent } from './admin/customer/zombie-sites/zombie-sites.component';
import { RejectedDeviceConnectionComponent } from './admin/logs/rejected-device-connection/rejected-device-connection.component';
import { ActionLogComponent } from './admin/logs/action-log/action-log.component';
import { BecomeUserComponent } from './users/become-user/become-user.component';
import { DailyDetailsComponent } from './home/site-dashboard/device/device-dashboard/daily-details/daily-details.component';
import { EditUserPasswordComponent } from './users/edit-user-password/edit-user-password.component';
import { ContactUsComponent } from './users/contact-us/contact-us.component';
import { ContactUsAdminComponent } from './admin/contact-us-admin/contact-us-admin.component';
import { ViewContactUsAdminComponent } from './admin/view-contact-us-admin/view-contact-us-admin.component';
import { BlockedFwReportsComponent } from './admin/blocked-fw-reports/blocked-fw-reports.component';
import { DevicePerformanceAnalyticsComponent } from './home/site-dashboard/device/device-dashboard/device-performance-analytics/device-performance-analytics.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxPrintModule } from 'ngx-print';
import { UsageDataComponent } from './home/usage-data/usage-data.component';
import { QuarterlyUsageDataComponent } from './home/quarterly-usage-data/quarterly-usage-data.component';
import { LoginNonConfirmedEmailComponent } from './users/login-non-confirmed-email/login-non-confirmed-email.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FooterService } from './shared/footer/footer.service';
import { DateRangeSelectComponent } from './home/site-dashboard/device/device-dashboard/date-range-select/date-range-select.component';
import { CookiePolicyComponent } from './home/cookie-policy/cookie-policy.component';
import { GoogleMapService } from './shared/google-map/google-map.service';
import { SitePerformanceComponent } from './home/site-dashboard/site-performance/site-performance.component';
import { ChartDataService } from './shared/chart-data-widget/chart-data.service';
import { PermissionsComponent } from './permissions/permissions.component';
import { PermissionsEditComponent } from './permissions/permissions-edit/permissions-edit.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NoteService } from './shared/note/note.service';
import { SiteNotesComponent } from './home/site-dashboard/site-notes/site-notes.component';
import { DeviceNotesComponent } from './home/site-dashboard/device/device-dashboard/device-notes/device-notes.component';
import { TagsManagementComponent } from './home/site-dashboard/tags-management/tags-management.component';
import { ReplaceDeviceComponent } from './device/replace-device/replace-device.component';
import { ManageDeviceRequestComponent } from './device/manage-device-request/manage-device-request.component';
import { ManagementRequestsComponent } from './admin/device/management-requests/management-requests.component';
import { DeviceRequestsComponent } from './device/management-requests/device-requests/device-requests.component';
import { CustomerRequestsComponent } from './device/management-requests/customer-requests/customer-requests.component';
import { SiteRequestsComponent } from './device/management-requests/site-requests/site-requests.component';
import { CustomerRequestEditComponent } from './device/management-requests/customer-request-edit/customer-request-edit.component';
import { SiteRequestEditComponent } from './device/management-requests/site-request-edit/site-request-edit.component';
import { DeviceReplacementRequestsComponent } from './device/management-requests/device-replacement-requests/device-replacement-requests.component';
import { SiteWarningsComponent } from './home/site-dashboard/site-warnings/site-warnings.component';
import { WarningsComponent } from './admin/warnings/warnings.component';
import { ForceReadDebugComponent } from './device/force-read-debug/force-read-debug.component';
import { DeviceAdminComponent } from './home/site-dashboard/device/device-dashboard/device-admin/device-admin.component';
import { TermsAndConditionsComponent } from './home/terms-and-conditions/terms-and-conditions.component';
import { MaintenanceReportComponent } from './home/site-dashboard/maintenance-report/maintenance-report.component';
import { ReceivingShipmentComponent } from './management/receiving-shipment/receiving-shipment.component';
import { ShipmentQualityComponent } from './management/shipment-quality/shipment-quality.component';
import { ShippingOutComponent } from './management/shipping-out/shipping-out.component';
import { SiteWarrantyComponent } from './site-dashboard/site-warranty/site-warranty.component';
import { WorkingDaysComponent } from './home/site-dashboard/working-days/working-days.component';
import { TimeLostReportComponent } from './admin/time-lost-report/time-lost-report.component';
import { DownloadStatusComponent } from './home/site-dashboard/download-status/download-status.component';
import { ActionsHistoryComponent } from './home/site-dashboard/device/device-dashboard/actions-history/actions-history.component';
import { warningSettingsComponent } from './home/site-dashboard/device/device-dashboard/warning-settings/warning-settings.component';
import { DeviceReportComponent } from './admin/device-report/device-report.component';
import { AdminActionsComponent } from './admin/sched/admin-actions/admin-actions.component';
import { GoogleAnalytics } from './shared/services/google-analytics.service';
import { AuditComponent } from './admin/audit/audit.component';
import { UserAuditComponent } from './admin/audit/user-audit/user-audit.component';
import { PermissionAuditComponent } from './admin/audit/permission-audit/permission-audit.component';
import { UpdateDeviceFwComponent } from './home/site-dashboard/device/device-dashboard/update-device-fw/update-device-fw.component';
import { MatButtonModule } from '@angular/material/button';
import { LandingPageComponent } from './users/landing-page/landing-page.component';
import { UsersAlertSettingsComponent } from './home/site-dashboard/users-alert-settings/users-alert-settings.component';
import { SchedRunComponent } from './admin/sched/sched-run/sched-run.component';
import { UploadFwFilesComponent } from './admin/upload-fw-files/upload-fw-files.component';
import { UnblockLoginComponent } from './users/unblock-login/unblock-login.component';
import { CellularSettingsComponent } from './home/site-dashboard/device/device-dashboard/cellular-settings/cellular-settings.component';
import { CellularTrackComponent } from './home/site-dashboard/device/device-dashboard/cellular-track/cellular-track.component';
import { OfficialReportComponent } from './home/site-dashboard/device/device-dashboard/official-report/official-report.component';
import { IccidChangesReportComponent } from './iccid-changes-report/iccid-changes-report.component';
import { ShipmentHistoryComponent } from './management/shipment-history/shipment-history.component';
import { DeviceAttachmentsComponent } from './home/site-dashboard/device/device-dashboard/device-attachments/device-attachments.component';
import { SiteAttachmentsComponent } from './home/site-dashboard/site-attachments/site-attachments.component';
import { SimManagementComponent } from './admin/sim-management/sim-management.component';
import { SmartRebatesSitesComponent } from './admin/smart-rebates-sites/smart-rebates-sites.component';
import { DisconnectedDevicesComponent } from './admin/disconnected-device/disconnected-device.component';
import { DisconnectedSitesComponent } from './admin/disconnected-sites-report/disconnected-sites-report.component';
import { ManageOrdersComponent } from './management/orders/manage-orders/manage-orders.component';
import { EditOrderComponent } from './management/orders/edit-order/edit-order.component';
import { RmaComponent } from './management/rma/rma.component';
import { RmaProcessingComponent } from './management/rma-processing/rma-processing.component';
import { ReceivingShipmentHistoryComponent } from './management/receiving-shipment-history/receiving-shipment-history.component';
import { ReceivingShipmentHistoryResultComponent } from './management/receiving-shipment-history-result/receiving-shipment-history-result.component';
import { RegistrationReportsComponent } from './registration-reports/registration-reports.component';
import { ApisLogsComponent } from './admin/apis-logs/apis-logs.component';
import { UnusedLogsComponent } from './admin/logs/unused-logs/unused-logs.component';
import { EditDevicesOwnersComponent } from './admin/device/edit-devices-owners/edit-devices-owners.component';
import { ConnectivityVerificationLogComponent } from './admin/logs/connectivity-verification-log/connectivity-verification-log.component';
import { FakeDataGeneratorComponent } from './admin/fake-data-generator/fake-data-generator.component';
import { UpdateFirmwareLogsComponent } from './admin/logs/update-firmware-logs/update-firmware-logs.component';
import { ConnectivityFrequencyComponent } from './home/site-dashboard/device/device-dashboard/connectivity-frequency/connectivity-frequency.component';
import { StudyComponent } from './study/study.component';
import { StudiesService } from './study/studies.service';
import { SiteStudiesComponent } from './home/site-dashboard/site-studies/site-studies.component';
import { MonitoringDevicesPageComponent } from './admin/monitoring-devices-page/monitoring-devices-page.component';
import { ReceivingShipmentService } from './management/receiving-shipment/receiving-shipment.service';
import { DeviceQueueChangesComponent } from './management/device-queue-changes/device-queue-changes.component';
import { ConnectivityVerificationStatisticsComponent } from './admin/logs/connectivity-verification-statistics/connectivity-verification-statistics.component';
import { DeviceLocationMismatchReportComponent } from './admin/device-location-mismatch-report/device-location-mismatch-report.component';
import { BulkFillDeviceInfoComponent } from './home/site-dashboard/device/bulk_fill_device_info/bulk_fill_device_info.component';
import { IncorrectEventsComponent } from './admin/incorrect-events/incorrect-events.component';
import { DisableExternalRtcComponent } from './management/disable-external-rtc/disable-external-rtc.component';
import { SuggestedInstallationDateReportComponent } from './noc-tools/suggested-installation-date-report/suggested-installation-date-report.component';
import { DisableXTRAFileComponent } from './management/disable-xtra-file/disable-xtra-file.component';
import { MissingSimsComponent } from './admin/missing-sims/missing-sims.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UsageTimeWidgetComponent } from './home/usage-time-widget/usage-time-widget.component';
import { SearchDeviceEventsReportComponent } from './noc-tools/search-device-events-report/search-device-events-report.component';
import { RtRequestsHistoryComponent } from './management/rt-requests-history/rt-requests-history.component';
import { RtRequestHistoryService } from './management/rt-requests-history/rt-request-history.service';
import { DefectiveDevicesPageComponent } from './noc-tools/defective-devices-page/defective-devices-page.component';
import { EbuAndChargeOpportunityComponent } from './home/ebu-and-charge-opportunity/ebu-and-charge-opportunity.component';
import { DebugLogsComponent } from './admin/debug-logs/debug-logs.component';
import { QuickViewHistoryComponent } from './noc-tools/quick-view-history/quick-view-history.component';
import { DevicesWithoutLocationComponent } from './management/devices-without-location/devices-without-location.component';
import { SitesGroupComponent } from './admin/sites-group/sites-group.component';
import { ChargerPredictionComponent } from './home/site-dashboard/device/device-dashboard/charger-prediction/charger-prediction.component';
import { ConfigureStudyDevicesComponent } from './management/configure-study-devices/configure-study-devices.component';
import { SCTTableModule } from "../../node_modules/sct-custom-table/sct-table/projects/sct-table/src/lib/sct-table.module";
import { AppVersionSettingComponent } from './admin/app-version-setting/app-version-setting.component';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader(new CommonDataService);
}
@NgModule({
	declarations: [
		AppComponent,
		MainLayoutComponent,
		SimpleLayoutComponent,
		PageNotFoundComponent,
		UnauthorizedComponent,
		CustomersComponent,
		LoginComponent,
		ForgotPasswordComponent,
		CaptchaComponent,
		UsersComponent,
		EditUserComponent,
		RegisterComponent,
		ConfirmUserEmailComponent,
		RegistrationRequestsComponent,
		ValidateRegistrationRequestsComponent,
		CustomerEditPageComponent,
		HomeComponent,
		EnterpriseComponent,
		EnterpriseEditComponent,
		SitesComponent,
		SiteEditPageComponent,
		CustomerInfoComponent,
		SearchDevicesComponent,
		ManageDevicePageComponent,
		ReverseEventsComponent,
		ReverseCalibrationComponent,
		ReverseRtComponent,
		EventsComponent,
		QuickViewComponent,
		RtComponent,
		ConnectivityTrackComponent,
		ErrorLogComponent,
		ViewErrorComponent,
		SchedControlComponent,
		SchedMonitorComponent,
		SiteDashboardComponent,
		EditAccessFunctionsComponent,
		SiteInfoComponent,
		DeviceActionsComponent,
		ConfigTrackComponent,
		GlobalRecordsComponent,
		SiteInfoComponent,
		SetAdcComponent,
		FirmwareUpdateComponent,
		DeviceSettingsComponent,
		ValidateFormDirective,
		DebugRecordsComponent,
		DeviceListingAdminComponent,
		DeviceEditPageComponent,
		simUsageManagementComponent,
		SiteEditComponent,
		CustomerEditComponent,
		PermissionEditComponent,
		DevicesListingComponent,
		BulkFillDeviceInfoComponent,
		DeviceDashboardComponent,
		DeviceSelectCriteriaComponent,
		SitesMapComponent,
		AdminComponent,
		ZombieSitesComponent,
		RejectedDeviceConnectionComponent,
		ActionLogComponent,
		BecomeUserComponent,
		DailyDetailsComponent,
		EditUserPasswordComponent,
		ContactUsComponent,
		ContactUsAdminComponent,
		ViewContactUsAdminComponent,
		BlockedFwReportsComponent,
		DevicePerformanceAnalyticsComponent,
		LoginNonConfirmedEmailComponent,
		UsageDataComponent,
		QuarterlyUsageDataComponent,
		// DateRangeSelectComponent,
		CookiePolicyComponent,
		SitePerformanceComponent,
		PermissionsComponent,
		PermissionsEditComponent,
		SiteNotesComponent,
		DeviceNotesComponent,
		TagsManagementComponent,
		ReplaceDeviceComponent,
		ManageDeviceRequestComponent,
		ManagementRequestsComponent,
		DeviceRequestsComponent,
		CustomerRequestsComponent,
		SiteRequestsComponent,
		CustomerRequestEditComponent,
		SiteRequestEditComponent,
		DeviceReplacementRequestsComponent,
		SiteWarningsComponent,
		WarningsComponent,
		DisconnectedDevicesComponent,
		DisconnectedSitesComponent,
		ForceReadDebugComponent,
		DeviceAdminComponent,
		TermsAndConditionsComponent,
		MaintenanceReportComponent,
		ReceivingShipmentComponent,
		ShipmentQualityComponent,
		ShippingOutComponent,
		SiteWarrantyComponent,
		WorkingDaysComponent,
		TimeLostReportComponent,
		DownloadStatusComponent,
		ActionsHistoryComponent,
		warningSettingsComponent,
		DeviceReportComponent,
		UserAuditComponent,
		AuditComponent,
		PermissionAuditComponent,
		AdminActionsComponent,
		UpdateDeviceFwComponent,
		LandingPageComponent,
		UsersAlertSettingsComponent,
		SchedRunComponent,
		UploadFwFilesComponent,
		UnblockLoginComponent,
		CellularSettingsComponent,
		CellularTrackComponent,
		OfficialReportComponent,
		SmartRebatesSitesComponent,
		IccidChangesReportComponent,
		ShipmentHistoryComponent,
		IncorrectEventsComponent,
		DeviceAttachmentsComponent,
		SiteAttachmentsComponent,
		SimManagementComponent,
		ManageOrdersComponent,
		EditOrderComponent,
		RmaComponent,
		RmaProcessingComponent,
		RegistrationReportsComponent,
		ReceivingShipmentHistoryComponent,
		ReceivingShipmentHistoryResultComponent,
		RegistrationReportsComponent,
		ApisLogsComponent,
		UnusedLogsComponent,
		EditDevicesOwnersComponent,
		ConnectivityVerificationLogComponent,
		FakeDataGeneratorComponent,
		UpdateFirmwareLogsComponent,
		StudyComponent,
		SiteStudiesComponent,
  		DeviceQueueChangesComponent,
 		ConnectivityVerificationStatisticsComponent,
		ConnectivityFrequencyComponent,
		DeviceLocationMismatchReportComponent,
		MonitoringDevicesPageComponent,
		DisableExternalRtcComponent,
		SuggestedInstallationDateReportComponent,
		DisableXTRAFileComponent,
		MissingSimsComponent,
		UsageTimeWidgetComponent,
		SearchDeviceEventsReportComponent,
		RtRequestsHistoryComponent,
		DefectiveDevicesPageComponent,
		EbuAndChargeOpportunityComponent,
		DebugLogsComponent,
		QuickViewHistoryComponent,
		DevicesWithoutLocationComponent,
		SitesGroupComponent,
		ChargerPredictionComponent,
		ConfigureStudyDevicesComponent,
		AppVersionSettingComponent,
	],
	imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
        cookieName: 'XSRF-TOKEN',
        headerName: 'X-XSRF-Token',
    }),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: TranslateFileLoaderFactory,
        }
    }),
    NgSelectModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatSortModule,
    MatSlideToggleModule,
    BsDatepickerModule.forRoot(),
    AgGridModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyCFxLjFwH5pExZgmEqWTovW8kWjWD4d3HE',
        libraries: ["places"]
    }),
    GoogleChartsModule,
    NgxPrintModule,
    FontAwesomeModule,
    TooltipModule.forRoot(),
    MatButtonModule,
    MatPaginatorModule,
    NgxSliderModule,
    SCTTableModule
],
	providers: [
		TranslateService,
		LoaderService,
		HeaderService,
		DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptorService,
			multi: true
		},
		NotificationMessageService,
		CommonDataService,
		ValidationService,
		NavigationService,
		AdminService,
		SideMenuService,
		StudiesService,
		LeftPanelService,
		DeviceManagementService,
		DeviceService,
		FooterService,
		BreadcrumbService,
		GoogleMapService,
		ChartDataService,
		NoteService,
		GoogleAnalytics,
		ReceivingShipmentService,
		RtRequestHistoryService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		// Add an icon to the library for convenient access in other components
		library.addIconPacks(fas, far, fab);
	}
}
