<app-modal #updateSetupDone [title]="'devices.change_is_done' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'devices.change_is_done_msg' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();approveRequest(selectedRequest, true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();approveRequest(selectedRequest, false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="updateSetupDone.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>
<app-modal #updateSetupDoneMulti [title]="'devices.change_is_done' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'devices.multi_change_is_done_msg' | translate}}
		<ul class="mt-2">
			<li *ngFor="let device of setupDoneDevices">
				{{device.mac_address}}
			</li>
		</ul>
	</div>
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="updateSetupDoneMulti.hide();approveRequestMulti(true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="updateSetupDoneMulti.hide();approveRequestMulti(false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="updateSetupDoneMulti.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>

<div class="main-black border-radius-bottom white-box mt-1" *ngIf="deviceOwners; else noRequests">
	<div class="card-body" *ngIf="requestsList.length > 0; else noRequests">
		<span *ngIf="selectedCount > 1; else noSelect">
			<h6 class="mb-3">
				{{'g.selected' | translate}}: {{selectedCount}}
				<button class="btn btn-outline-success ml-2" (click)="onBeforeApproveRequestMulti()">
					{{'device_management.approve' | translate}}
				</button>
				<button class="btn btn-outline-danger ml-2" (click)="confirmAction('rejectRequestMulti')">
					{{'g.reject' | translate}}
				</button>
			</h6>
		</span>
		<ng-template #noSelect>
			<h6 class="mb-3">{{requestsList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		</ng-template>
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
			(updateSelectedRows)="updateSelectedRequests($event)"
		></sct-custom-table>
	</div>
</div>
<ng-template #noRequests>
	<div class="main-black border-radius-bottom white-box mt-1">
		<div class="card-body" *ngIf="!requestsList || !requestsList.length">
			<h3>{{'device_management.no_new_move' | translate}}</h3>
		</div>
	</div>
</ng-template>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-request="request">
		<span mat-menu-item class="text-success" (click)="onBeforeApproveRequest(request)">
			{{'device_management.approve' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmAction('rejectRequest', request.id)">
			{{'g.reject' | translate}}
		</span>
		<span
			mat-menu-item
			(click)="showDeviceLocation(request)"
			*ngIf="request.source == 'device' && devicesInfo[request.mac_address]?.latitude && devicesInfo[request.mac_address]?.longitude"
		>
			{{'device_management.view_location' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
<app-modal #locationModal title="{{'g.locations' | translate}}" size="lg" [hasConfirmButton]="false">
	<app-mapbox *ngIf="locationsArr.length > 0"
		class="border-radius main-blue-border"
		[latitude]="sitesService.defaultMapLocation.latitude"
		[longitude]="sitesService.defaultMapLocation.longitude"
		zoom="3.5"
		[showPopup]="false"
		[dots]="locationsArr"
	></app-mapbox>
</app-modal>
