import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from 'src/app/users/users.service';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-app-version-setting',
	templateUrl: './app-version-setting.component.html',
	styleUrls: ['./app-version-setting.component.css']
})

export class AppVersionSettingComponent implements OnInit {
	currentUser: any;
	appVersionsInfo: any = {
		min_app_version: '',
		latest_app_version: '',
	};

	constructor (
		private router: Router,
		private userService: UsersService,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
	) {}

	ngOnInit() {
		if (!this.userService.hasAccessFunction('dev_team'))
			return this.router.navigate(['/unauthorized']);

		this.currentUser = this.userService.getCurrentUser();
		return this.getCurrentAppVersionInfo();
	}

	saveAppVersionSettings() {
		if (this.validateAppVersionSettings()) {
			this.appVersionsInfo.user_id = this.currentUser.id;
			delete this.appVersionsInfo.last_change_time;
			this.adminService.saveAppVersionSettings(this.appVersionsInfo).subscribe((res) => {
				this.appVersionsInfo = res;
				this.appVersionsInfo.created_at = new Date(this.appVersionsInfo?.created_at || 0).toLocaleString();
				return this.notificationMessage.setMessage('globalSuccessMsg');
			});
		}
	}

	getCurrentAppVersionInfo() {
		this.adminService.getCurrentAppVersionInfo().subscribe((res: any) => {
			this.appVersionsInfo = res;
			this.appVersionsInfo.created_at = this.appVersionsInfo?.created_at ? new Date(this.appVersionsInfo?.created_at).toLocaleString() : '---';
			this.appVersionsInfo.user_name = this.appVersionsInfo?.user_name ? this.appVersionsInfo?.user_name : '---';
		});
	}

	validateAppVersionSettings() {
		const { min_app_version, latest_app_version } = this.appVersionsInfo;
	
		if (!min_app_version || !latest_app_version) {
			this.notificationMessage.setMessage('translate|g.field_is_required');
			return false;
		}
	
		const isValidVersion = (version: string): boolean => {
			return version.split('.').every(part => !isNaN(Number(part)) && part.trim() !== '');
		};
	
		if (!isValidVersion(min_app_version) || !isValidVersion(latest_app_version)) {
			this.notificationMessage.setMessage('translate|g.invalid_input');
			return false;
		}
		return true;
	}
}