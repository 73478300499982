<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="!hideChart">
	<h4>
		{{'perf_analytic.usage_time' | translate}}
		<app-help-tooltip *ngIf="true" [field]="tooltip"></app-help-tooltip>
	</h4>

	<!-- time filter -->
	<div class="row py-4" *ngIf="!hideDateSelector">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': invalidDateRange}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDate" (ngModelChange)="DatesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="DatesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>

	<!-- devices filter -->
	<div class="row py-4">
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasYearFilter">
			<ng-select class="mr-auto w-100" [items]="ddList.truckYearsList" bindLabel="label" bindValue="value" placeholder="{{'devices.truck_manufacturing_year' | translate}}" name="truckYear" [(ngModel)]="selectedTruckYear" (ngModelChange)="getUsageTime()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="options.hasTruckTypeFilter">
			<ng-select class="mr-auto w-100" [items]="ddList.truckTypes" bindLabel="text" bindValue="id" placeholder="{{'devices.truck_type' | translate}}" name="truckTypes" [(ngModel)]="selectedTruckType" (ngModelChange)="getUsageTime()" [clearable]="false">
			</ng-select>
		</div>
		<div class="col-sm-6 col-md-4 col-lg-2 d-inline-flex" *ngIf="tags && tags.length > 0">
			<ng-select
				[multiple]="true"
				[items]="tags"
				[addTag]="false"
				[clearable]="false"
				[hideSelected]="true"
				bindLabel="tag_name"
				bindValue="id"
				(ngModelChange)="onSelectedTagsChanges($event)"
				[placeholder]="'tags.filter_by_tag' | translate"
				[(ngModel)]="selectedTags"
				class="mr-auto w-100"
			></ng-select>
		</div>
	</div>

	<div class="d-flex">
		<div class="option mr-3 pointer" *ngFor="let event of events" (click)="toggleEventsVisibility(event)">
			<fa-icon class="mr-1"  [icon]="['far', eventsVisibility[event] ? 'check-square': 'square']" size="sm"></fa-icon>
			{{'usage_time_chart.'+ event | translate}}
		</div>
	</div>

	<sct-charts
		*ngIf="!noData"
		id="usage-time-widget"
		title="{{'perf_analytic.usage_time_widget' | translate}}"
		[data]=" data"
		[columnNames]="['Event', 'Second']"
		[options]="chartOptions"
		[type]="'PieChart'"
		[height]="500"
	></sct-charts>

	<div class="card-body" *ngIf="noData">
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</div>
</div>
