<div class="col-md-12">
	<form #enterpriseForm="ngForm" (ngSubmit)="saveEnterprise()" validate-form="editEnterprise">
		<div class="row">
			<div [ngClass]="{'col-6': (!enterprise.id), 'col-12': enterprise.id}">
				<div class="mt-3">
					<label for="name">{{'g.enterprise_name' | translate}}</label>
					<span class="ml-1">*</span>
					<input required type='text' class='form-control' [(ngModel)]='enterprise.name' name="name"/>
				</div>
				<div class="mt-3">
					<label for='group_type'>{{'g.group_type' | translate}}</label>
					<span class="ml-1">*</span>
					<ng-select
						[items]="groupTypes"
						bindLabel="text"
						bindValue="val"
						placeholder="{{'ent.select_group_type' | translate}}"
						name="group_type"
						[(ngModel)]="enterprise.group_type"
						required></ng-select>
				</div>
				<div class="mt-3">
					<label>{{'permissions.permission' | translate}}</label>
					<span class="ml-1">*</span>
					<ng-select
						[items]="permissionList"
						bindLabel="name"
						bindValue="id"
						name="default_permission_id"
						placeholder="{{'permissions.permission' | translate}}"
						[(ngModel)]="enterprise.default_permission_id"
						[clearable]="false"
						(ngModelChange)="enterprise.update_linked = false"
						required
					></ng-select>
				</div>
				<div class="form-check mt-3" *ngIf="originalPermissionId && enterprise.default_permission_id != originalPermissionId">
					<input class="form-check-input" type="checkbox" name="update_linked" [(ngModel)]="enterprise.update_linked" id="update_linked">
					<label class="form-check-label" for="update_linked">{{'permissions.update_linked_sites' | translate}}</label>
				</div>
				<ng-container *ngIf="!enterprise.id">
					<h5 class="mt-3">
						{{'site.inventory_site_info' | translate}}
					</h5>
					<div class="mt-3">
						<label for="country_id">{{'site_edit.country' | translate}}</label>
						<span class="ml-1">*</span>
						<input type="text" class="form-control" [(ngModel)]="enterprise.site.country_id" placeholder="{{'site_edit.country' | translate}}" name="country_id" id="country_id" [readonly]="true" required/>
					</div>
					<div class="mt-3">
						<label for="state_id">{{'site_edit.state' | translate}}</label>
						<span class="ml-1">*</span>
						<input type="text" class="form-control" [(ngModel)]="enterprise.site.state_id" placeholder="{{'site_edit.state' | translate}}" name="state_id" id="state_id" [readonly]="true" required/>
					</div>
					<div class="mt-3">
						<label for="address">{{'site_edit.address' | translate}}</label>
						<span class="ml-1">*</span>
						<input type="text" class="form-control" [(ngModel)]="enterprise.site.address" placeholder="{{'site_edit.address' | translate}}" name="address" id="address" [readonly]="true" required/>
					</div>
					<div class="mt-3">
						<label for="zone_id">{{'site_edit.time_zone' | translate}}</label>
						<span class="ml-1">*</span>
						<ng-select [items]="timeZonesMenu" bindLabel="display_name" bindValue="id" [(ngModel)]="enterprise.site.zone_id" placeholder="{{'g.select_time_zone' | translate}}" name="zone_id" id="zone_id" [clearable]="false" required>
						</ng-select>
					</div>
					<div class="mt-3">
						<label for="automatically_generate_customer">{{'site_edit.auto_generate_customer' | translate}}</label>
					</div>
					<div>
						<mat-slide-toggle [(ngModel)]="enterprise.auto_generate_customer" name="rt_sync"></mat-slide-toggle>
					</div>
				</ng-container>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary mr-2">{{'g.submit' | translate}}</button>
					<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
				</div>
			</div>
			<div class="col-6" *ngIf="showMapBox">
				<app-mapbox
					class="border-radius main-blue-border"
					[latitude]="map.latitude"
					[longitude]="map.longitude"
					[zoom]="3"
					[showPopup]="false"
					[dots]="[map]"
					[clickEnabled]="true"
					(onAddressChanged)="updateSiteCoords($event)"
				></app-mapbox>
			</div>
		</div>
	</form>
</div>
