<mgl-map
	*ngIf="latitude !== undefined && longitude !== undefined && sideMenuService.showMap"
	[style]="'mapbox://styles/mapbox/light-v10'"
	[zoom]="zoom"
	[center]="center"
	(click)="setCurrentPosition($event)"
	(mapLoad)="onMapLoad($event)"
>
	<mgl-control mglGeocoder (result)="onGeocoderResult($event)" *ngIf="clickEnabled"></mgl-control>
	<ng-container *ngFor="let dot of dots">
		<ng-container *ngIf="showPopup && (dot.draw || !dot.group)">
			<mgl-marker #myMarker [lngLat]="[dot.longitude, dot.latitude]">
				<div class="ring-container">
					<div class="ringring" [ngClass]="{'blueringring': !dot.opened, 'greenringring': dot.opened}"></div>
					<div class="circle" [style.--color]="dot.color || '#23ABE2'" [ngClass]="{'bluecircle': !dot.opened, 'greencircle': dot.opened}"></div>
				</div>
			</mgl-marker>
			<mgl-popup [marker]="myMarker" [closeButton]="false" [offset]="[3,-30]" (close)="dot.opened = false" (open)="dot.opened = true">
				<div [ngSwitch]="dotsType">

					<div *ngSwitchCase="'sites'" class="p-2">
						<h4 class="font-weight-bold"><a [routerLink]="['/', dot.customer_id, dot.id]">{{dot.name}}</a></h4>
						<div>{{dot.address}}</div>
						<hr>
						<div *ngIf="dot.iotah_devices_count"><b class="pr-1">{{dot.iotah_devices_count}}</b> {{'g.iotah_devices' | translate}}</div>
						<div *ngIf="dot.chargLink_devices_count"><b class="pr-1">{{dot.chargLink_devices_count}}</b> {{'chargeLink.site.iotah_devices' | translate}}</div>
						<div *ngIf="dot.iotah_ps_devices_count"><b class="pr-1">{{dot.iotah_ps_devices_count}}</b> {{ 'g.iotah_ps_devices' | translate}}</div>
						<div *ngIf="!dot.iotah_devices_count && !dot.chargLink_devices_count && !dot.iotah_ps_devices_count">
							<b class="pr-1">0</b> {{ 'g.devices' | translate}}
						</div>
					</div>

					<div *ngSwitchCase="'mix'" class="p-2">
						<div *ngIf="dot.type == 'site'">
							<h4 class="font-weight-bold"><a [routerLink]="['/', dot.customer_id, dot.id]">{{dot.name}}</a></h4>
							<div>{{dot.address}}</div>
							<hr>
							<div *ngIf="dot.iotah_devices_count"><b class="pr-1">{{dot.iotah_devices_count}}</b> {{'g.iotah_devices' | translate}}</div>
							<div *ngIf="dot.chargLink_devices_count"><b class="pr-1">{{dot.chargLink_devices_count}}</b> {{'chargeLink.site.iotah_devices' | translate}}</div>
							<div *ngIf="dot.iotah_ps_devices_count"><b class="pr-1">{{dot.iotah_ps_devices_count}}</b> {{ 'g.iotah_ps_devices' | translate}}</div>
							<div *ngIf="!dot.iotah_devices_count && !dot.chargLink_devices_count && !dot.iotah_ps_devices_count">
								<b class="pr-1">0</b> {{ 'g.devices' | translate}}
							</div>
						</div>

						<div *ngIf="dot.type == 'device' && (!dot.group)">
							<h6><b class="pr-1">{{(dot.is_charglink ? 'g.charglink_sn' : 'g.iotah_sn') | translate}}: </b> <a [routerLink]="['/', dot.customer_id, dot.site_id, dot.mac_address, 'performance']">{{dot.serial_number}}</a></h6>
							<h6><b class="pr-1">{{(dot.is_charglink ? 'smart_rebates_reports.charger_sn' : 'smart_rebates_reports.truck_sn') | translate}}: </b>{{dot.truck_sn}}</h6>
							<h6><b class="pr-1">{{(dot.is_charglink ? 'devices.charger_id' : 'devices.truck_id') | translate}}: </b>{{dot.truck_id}}</h6>
						</div>

						<div *ngIf="dot.type == 'device' && dot.group" class="group-popup">
							<div *ngFor="let data of dot.data" class="group-item">
								<h6><b class="pr-1">{{(data.is_charglink ? 'g.charglink_sn' : 'g.iotah_sn') | translate}}: </b> <a [routerLink]="['/', data.customer_id, data.site_id, data.mac_address, 'performance']">{{data.serial_number}}</a></h6>
								<h6><b class="pr-1">{{(data.is_charglink ? 'smart_rebates_reports.charger_sn' : 'smart_rebates_reports.truck_sn') | translate}}: </b>{{data.truck_sn}}</h6>
								<h6><b class="pr-1">{{(data.is_charglink ? 'devices.charger_id' : 'devices.truck_id') | translate}}: </b>{{data.truck_id}}</h6>
							</div>
						</div>
					</div>

				</div>
			</mgl-popup>
		</ng-container>
		<ng-container *ngIf="!showPopup && (dot.draw || !dot.group)">
			<mgl-marker #myMarker [lngLat]="[dot.longitude, dot.latitude]">
				<div class="ring-container">
					<div class="ringring" [ngClass]="{'blueringring': !dot.opened, 'greenringring': dot.opened}"></div>
					<div class="circle" [style.--color]="dot.color || '#23ABE2'"  [ngClass]="{'bluecircle': !dot.opened, 'greencircle': dot.opened}"></div>
				</div>
			</mgl-marker>
		</ng-container>
	</ng-container>
</mgl-map>
