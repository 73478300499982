import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from './header.service';
import { NavigationService } from '../services/navigation.service';
import { CommonDataService } from '../services/common-data.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { ValidationService } from '../services/validation.service';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	@Input() visibleSideMenu: boolean;
	user: any = {};
	showHeader = false;
	isProduction: boolean = false;
	isStaging: boolean = false;
	hideHeader: boolean = true;

	headerLogo: string = '';

	searchString = '';
	searchType = 'normal-search';
	searchDevicesResult: any = {};
	@ViewChild('searchInput') searchInput: ElementRef;

	constructor(
		public usersService: UsersService,
		public header: HeaderService,
		private router: Router,
		private navigation: NavigationService,
		private deviceManagementService: DeviceManagementService,
		private commonData: CommonDataService,
		public commonService: CommonService,
		private sideMenuService: SideMenuService,
		private route: ActivatedRoute,

	) { }

	ngOnInit() {
		this.checkIsCharglink();
		const currentDomain = window.location.hostname;

		this.isProduction = [this.commonData.productionDomain, this.commonData.charglinkProductionDomain]
			.some(domain => domain.includes(currentDomain));

		this.isStaging = [this.commonData.stagingDomain, this.commonData.charglinkStagingDomain]
			.some(domain => domain == currentDomain);

		this.usersService.currentUser.subscribe((user: any) => {
			if (user && Object.keys(user).length > 0) {
				this.user = user;
			}
		});
		this.usersService.getCurrentUser();
		this.header.toggleSearchBox(this.header.SearchBoxVisible);
		this.hideHeader = this.route.snapshot.firstChild?.data.hideItems;

	}

	checkIsCharglink() {
		let isChargeLink = this.commonData.chargLinkDomains.includes(window.location.hostname);
		if (isChargeLink == true)
			return this.headerLogo = 'brand-logo-charg-link'
		return this.headerLogo = 'brand-logo ml-4';
	}

	logout() {
		this.usersService.logout().subscribe((done: boolean) => {
			this.navigation.setPreviousUrl(null, true);
			this.usersService.setCurrentUser({});
			this.sideMenuService.setData([]);
			this.router.navigate(['/login']);
		});
	}

	toggleHeader() {
		this.showHeader = !this.showHeader;
	}

	closeMobileHeader() {
		this.showHeader = false;
	}

	userHasAdminLogsTab() {
		return (
			this.usersService.hasAccessFunction('search_users') ||
			this.usersService.hasAccessFunction('registration_requests') ||
			this.usersService.hasAccessFunction('admin_contact_us') ||
			this.usersService.hasAccessFunction('access_error_logs') ||
			this.usersService.hasAccessFunction('access_action_logs') ||
			this.usersService.hasAccessFunction('access_rejection_logs') ||
			this.usersService.hasAccessFunction('sched_functions_control') ||
			this.usersService.hasAccessFunction('add_device') ||
			this.usersService.hasAccessFunction('dev_team') ||
			this.usersService.hasAccessFunction('site_management') ||
			this.usersService.hasAccessFunction('device_management') ||
			this.user.is_admin
		);
	}

	enableFullAccessMode() {
		this.usersService.enableFullAccessMode().subscribe(
			(data) => {
				this.usersService.updateCurrentUser(data);
				window.location.reload();
			}
		);
	}

	searchDevices() {
		try {
			JSON.parse(this.searchString);
		} catch (ex) {
			this.searchString = this.searchString.trim().replace(/:/g, "");
		}

		let searchBy = 'sn';
		if (/^[a-fA-F0-9]{12}$/.test(this.searchString))
			searchBy = 'mac';
		if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.searchString))
			searchBy = 'ip'

		if (!this.searchString)
			return;

		this.deviceManagementService.searchDevices(this.searchString, searchBy, false, 'no', this.searchType).subscribe((data: any) => {
			let devicePath = [];
			let sitePath = [];
			if (data.devices.length == 1) {
				let device = data.devices[0];
				if (data.sites && data.sites[device.site_id] && !device.is_deleted) {
					let customerId = data.sites[device.site_id].customer_id;
					devicePath = ['/', customerId, device.site_id, device.mac_address, 'performance'];
					sitePath = ['/', customerId, device.site_id];
				}
			}
			if (devicePath.length) {
				this.router.navigate(sitePath).then(() => {
					this.router.navigate(devicePath);
				});
			} else {
				if (/^[a-fA-F0-9]{6,12}$/.test(this.searchString)) {
					//Detect (partial) search type
					searchBy = 'mac';
				}
				this.router.navigate(
					['/device/search'],
					{
						queryParams: {
							searchString: this.searchString,
							searchBy
						}
					}
				);
			}
		});
	}
}
