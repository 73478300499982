import { Component, OnInit, Input, OnChanges, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from '../../device.service';

import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DecimalPipe } from '@angular/common';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-device-performance-analytics',
	templateUrl: './device-performance-analytics.component.html',
	styleUrls: ['./device-performance-analytics.component.css']
})
export class DevicePerformanceAnalyticsComponent implements OnInit, OnChanges, OnDestroy {

	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() enterprisePermissions: any = {};
	@Input() studyInfo: any = {};
	voltageLevels: any ;

	currentUser: any = {};
	data: any;
	dailyDetails: any;
	endVoltageDailyDetails: any;
	usageDailyDetails: any;
	truckHoursDailyDetails: any;

	dateRange: any = {};

	isCollapsed: boolean = false;
	idleChartColumnNames: any = [];
	endVoltageChartColumnNames: any = [];
	usageAmpColumnNames: any = [];
	usageKwColumnNames: any = [];
	truckHoursChartColumnNames: any = [];
	tableItems = [];
	idleChartData: any = {};
	endVoltageChartData: any = [];
	endVoltageTableItems: any = [];
	endVoltageTableColumns: any = [];
	endVoltageAllTableItems: any = [];
	usageChartDataAmp: any = [];
	usageChartDataWatt: any = [];
	truckHoursChartData: any = [];
	idleTimesOptions:any = {
		hAxis: {textStyle : {fontSize: 12}},
		legend: {position: 'none'}
	};
	endVoltageOptions:any = {
		legend: {position: 'none'}
	};
	truckHoursOptions:any = {
		legend: {position: 'none'}
	};
	usageAmpOptions:any = {
		legend: {position: 'none'},
		explorer: {
			"actions": [
				"dragToZoom",
				"rightClickToReset"
			],
			maxZoomIn: 50.0,
			keepInBounds: true
		},
	};
	usageKwOptions:any = {
		legend: {position: 'none'},
		explorer: {
			"actions": [
				"dragToZoom",
				"rightClickToReset"
			],
			maxZoomIn: 50.0,
			keepInBounds: true
		},
	};
	deviceInstallationDate: any = null;
	idleTimesFromDate: Date;
	idleTimesToDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
	idleTimesInvalidDateRange: boolean = false;
	endVoltageFromDate: Date;
	endVoltageToDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
	usageFromDate: Date;
	usageToDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
	maxDate = new Date();
	truckHoursFromDate: Date;
	truckHoursToDate: Date = new Date(new Date().setDate(new Date().getDate() - 1));
	endVoltageInvalidDateRange: boolean = false;
	usageInvalidDateRange: boolean = false;
	truckHoursInvalidDateRange: boolean = false;
	deviceVPC;
	idleChartReady = false;
	endVoltageChartReady = false;
	usageChartReady = false;
	truckHoursChartReady = false;
	idleDataFilter: string = 'avg';

	tooltips = {
		device_tags: '',
		idle_events_report: '',
		end_voltage_report: '',
		total_truck_usage: '',
		daily_truck_hours: ''
	};

	permissionsSub: Subscription = new Subscription();
	chartsReady: any = {
		idle_chart_avg: false,
		idle_chart_total: false
	};

	chargLinkTranslationPath: string = "";
	is_chargLink: boolean;
	studyStartTime: Date;
	studyEndTime: Date;

	@ViewChild('endVoltageModal') endVoltageModal;

	constructor(
		public usersService: UsersService,
		private deviceService: DeviceService,
		private translate: TranslateService,
		public commonService: CommonService,
		private commonData: CommonDataService,
		private userService: UsersService,
		private siteDashboardService: SiteDashboardService,
		private chRef: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.chargLinkTranslationPath = this.device.config_info.is_charglink ? this.commonData.chargLinkTranslationPath : "";
		this.voltageLevels = this.currentSite.voltage_levels ? this.currentSite.voltage_levels : this.siteDashboardService.voltageLevels;
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			for(let key in this.tooltips) {
				this.tooltips[key] = this.commonService.getTooltipKey(key, this.userService.userHasNOCAccess(data));
				this.tooltips[key] = this.tooltips[key] ? this.chargLinkTranslationPath + this.tooltips[key] : this.tooltips[key];
			}
		});
		this.currentUser = this.usersService.getCurrentUser();
		this.idleChartColumnNames = [this.translate.instant('g.type'), this.translate.instant('perf_analytic.period_mins'), {type: 'string', role: 'style'}];
		this.idleTimesOptions.vAxis = {title: this.translate.instant('g.minutes')};

		this.endVoltageChartColumnNames = [this.translate.instant('rt.sequence_id'), this.translate.instant('g.events'), {type: 'string', role: 'style'}];
		this.endVoltageOptions.vAxis = {title: this.translate.instant('g.events')};
		this.endVoltageOptions.hAxis = {title: this.translate.instant('event.vpc')};

		this.usageAmpColumnNames = [this.translate.instant('rt.sequence_id'), this.translate.instant('perf_analytic.total_amp_usage')];
		this.usageKwColumnNames = [this.translate.instant('rt.sequence_id'), this.translate.instant('perf_analytic.total_kw_usage')];
		this.usageAmpOptions.vAxis = {title: this.translate.instant('g.amp')};
		this.usageAmpOptions.hAxis = {title: this.translate.instant('g.time')};

		this.usageKwOptions.vAxis = {title: this.translate.instant('g.kw')};
		this.usageKwOptions.hAxis = {title: this.translate.instant('g.time')};

		this.truckHoursChartColumnNames = [this.translate.instant('g.date'), this.translate.instant('site_performance.time_report_title'), /*{type: 'string', role: 'style'}*/];
		this.truckHoursOptions.vAxis = {title: this.translate.instant('site_performance.time_report_title')};
		this.truckHoursOptions.hAxis = {title: this.translate.instant('g.date')};

		this.endVoltageTableColumns = [
			{headerName: this.translate.instant('events.sequence_id'), field: 'eventId'},
			{headerName: this.translate.instant('events.timestamp'), field: 'time'},
		];
	}

	ngOnChanges(changes){
		if(changes.device && this.device && this.device.mac_address) {
			this.chargLinkTranslationPath = changes.device.currentValue.config_info.is_charglink ? this.commonData.chargLinkTranslationPath : "";
			this.is_chargLink = changes.device.currentValue.config_info.is_charglink? true : false;
			this.deviceService.formatDeviceFields(this.device.config_info);
			this.deviceInstallationDate = null;
			if(this.device.config_info.installation_date > 0)
				this.deviceInstallationDate = new Date(this.device.config_info.installation_date * 1000);

			let fromDateRange = new Date(new Date().setMonth(new Date().getMonth() - 3));
			if(fromDateRange < this.deviceInstallationDate)
				fromDateRange = this.deviceInstallationDate;

			if(this.studyInfo.id && fromDateRange < this.studyStartTime)
				fromDateRange = this.studyStartTime;

			this.idleTimesFromDate = fromDateRange;
			this.endVoltageFromDate = fromDateRange;

			let last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
			if(last7Days < this.deviceInstallationDate)
				last7Days = this.deviceInstallationDate;
			this.usageFromDate = last7Days;
			this.truckHoursFromDate = last7Days;

			this.deviceVPC = this.device.config_info.vpc;
			if(this.deviceVPC === undefined) {
				this.deviceVPC = (this.device.config_info.battery_type == this.deviceService.batteryTypesByName.lithuim_ion) ? 3 : 2;
			}

			if (this.studyEndTime < this.maxDate) {
				this.maxDate = this.studyEndTime;
			}

			if(this.studyInfo?.id)
				this.handleStudyDeviceStartEndDate();

			this.fetchData();
			if(!this.device.config_info.is_charglink) {
				this.getDailyDetails('idleTimes');
				this.getDailyDetails('endVoltage');
			}
			this.getDailyDetails('usage');
			this.getDailyDetails('truckHours');

			this.device.tags.siteTagsValues = Object.values(this.device.tags.siteTags);

			if (typeof this.device.config_info.shipping_date == 'number')
				this.device.config_info.shipping_date = this.device.config_info.shipping_date ? moment.unix(this.device.config_info.shipping_date).utc().format('MM/DD/YYYY') : '-';
			else
				this.device.config_info.shipping_date ||= '-';
		}

		if (changes.studyInfo && this.studyInfo?.id)
			return this.handleStudyDeviceStartEndDate();
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

	fetchData() {
		if (!this.device || !this.device.mac_address)
			return;

		this.deviceService.getPerformanceAnalytics(this.device.mac_address, this.studyInfo?.id || 0).subscribe((data: any) => {
			this.data = data || {};
		});
	}

	getIdleTimesColors(value) {
		if(value < 30)
			return '#109618'; //green
		if(value < 60)
			return '#3366cc'; //blue
		if(value < 120)
			return 'yellow';
		if(value < 240)
			return '#ff9900'; //orange
		return '#dc3912'; //red
	}

	getEndVoltageColors(value) {
		const matchedLevel = this.voltageLevels.find((level) => value >= level.min && value < level.max);
		const color = matchedLevel ? matchedLevel.color : this.voltageLevels[0].color;
		return color;
	}

	canAccessFirstGroup() {
		return this.usersService.hasAccessPermission(this.enterprisePermissions, 'truck_id') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'truck_type') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'truck_sn') ||
				this.usersService.hasAccessPermission(this.enterprisePermissions, 'serial_number');
	}

	idleTimesDatesChanged() {
		this.chartsReady = {
			idle_chart_avg: false,
			idle_chart_total: false
		};
		this.idleTimesInvalidDateRange = false;
		if(moment(this.idleTimesFromDate).unix() > moment(this.idleTimesToDate).unix())
			this.idleTimesInvalidDateRange = true;

		this.getDailyDetails('idleTimes');
	}

	endVoltageDatesChanged() {
		this.endVoltageInvalidDateRange = false;
		if(moment(this.endVoltageFromDate).unix() > moment(this.endVoltageToDate).unix())
			this.endVoltageInvalidDateRange = true;

		this.getDailyDetails('endVoltage');
	}

	usageDatesChanged() {
		this.usageInvalidDateRange = false;
		if(moment(this.usageFromDate).unix() > moment(this.usageToDate).unix())
			this.usageInvalidDateRange = true;

		this.getDailyDetails('usage');
	}

	truckHoursDatesChanged() {
		this.truckHoursInvalidDateRange = false;
		if(moment(this.truckHoursFromDate).startOf('day').unix() > moment(this.truckHoursToDate).startOf('day').unix())
			this.truckHoursInvalidDateRange = true;
		this.getDailyDetails('truckHours');
	}

	private getDailyDetails(type) {
		this.dailyDetails = [];
		if(this.idleTimesInvalidDateRange) {
			this.getIdleChartData();
			return;
		}

		let zoneDiff = new Date().getTimezoneOffset() * -1 * 60 * 1000;
		let fromDate:any, toDate:any;

		switch(type) {
			case 'idleTimes':
				fromDate	= new Date(new Date(this.idleTimesFromDate).getTime() + zoneDiff);
				toDate		= new Date(new Date(this.idleTimesToDate).getTime() + zoneDiff);
				fromDate	= moment(fromDate).utc().startOf('day').unix();
				toDate		= moment(toDate).utc().endOf('day').unix();

				this.deviceService.getDailyDetails(this.device.mac_address, fromDate, toDate, {chart: true}, this.studyInfo?.id || 0).subscribe(data => {
					this.dailyDetails = data;
					this.getIdleChartData();
				});
			break;
			case 'endVoltage':
				fromDate	= new Date(new Date(this.endVoltageFromDate).getTime() + zoneDiff);
				toDate		= new Date(new Date(this.endVoltageToDate).getTime() + zoneDiff);
				fromDate	= moment(fromDate).utc().startOf('day').unix();
				toDate		= moment(toDate).utc().endOf('day').unix();

				this.deviceService.getDailyDetails(this.device.mac_address, fromDate, toDate, {}, this.studyInfo?.id || 0).subscribe(data => {
					this.endVoltageDailyDetails = data;
					this.endVoltageChartData = this.getEndVoltageChartData();
				});
			break;
			case 'usage':
				fromDate	= new Date(new Date(this.usageFromDate).getTime() + zoneDiff);
				toDate		= new Date(new Date(this.usageToDate).getTime() + zoneDiff);
				fromDate	= moment(fromDate).utc().startOf('day').unix();
				toDate		= moment(toDate).utc().endOf('day').unix();

				this.deviceService.getDailyDetails(this.device.mac_address, fromDate, toDate, {}, this.studyInfo?.id || 0).subscribe(data => {
					if(Object.keys(data).length) {
						this.usageDailyDetails = data;
						let usageChartData:any = {};
						
						if(this.device.config_info.is_charglink)
							usageChartData = this.getChargerUsageChartData();
						else
							usageChartData = this.getUsageChartData();

						this.usageChartDataAmp = usageChartData.amp;
						this.usageChartDataWatt = usageChartData.watt;
					}
				});
			break;
			case 'truckHours':
				fromDate	= new Date(new Date(this.truckHoursFromDate).getTime() + zoneDiff);
				toDate		= new Date(new Date(this.truckHoursToDate).getTime() + zoneDiff);
				fromDate	= moment(fromDate).utc().startOf('day').unix();
				toDate		= moment(toDate).utc().endOf('day').unix();

				this.deviceService.getDailyDetails(this.device.mac_address, fromDate, toDate, {}, this.studyInfo?.id || 0).subscribe(data => {
					this.truckHoursDailyDetails = data;
					this.truckHoursChartData = this.getTruckHoursChartData();
				});
			break;
		}
	}

	private getIdleChartData() {
		let hours = ['12AM','1AM','2AM','3AM','4AM','5AM','6AM','7AM','8AM','9AM','10AM','11AM','12PM','1PM','2PM','3PM','4PM','5PM','6PM','7PM','8PM','9PM','10PM','11PM'];

		let idleTimes			= [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

		let totalDays = 0;
		for (let dayIndex in this.dailyDetails) {
			let currentDay = this.dailyDetails[dayIndex];

			if(!Object.keys(currentDay).length)
				continue;

			if(currentDay.non_plugged_in_idle_times) {
				for (let hour = 0; hour < 24; hour++) {
					idleTimes[hour] += currentDay.non_plugged_in_idle_times[hour];
				}
			}
			totalDays++;
		}

		let idle_times = {total: [], avg: []};
		for(let i in idleTimes) {
			let total = +idleTimes[i],
				avg = total / totalDays;
			idle_times.total.push([hours[i], total, 'color: ' + this.getIdleTimesColors(total)]);
			idle_times.avg.push([hours[i], avg, 'color: ' + this.getIdleTimesColors(avg)]);
		}

		this.idleChartReady = true;
		this.idleChartData = idle_times;
	}

	private getEndVoltageChartData() {
		let min = Math.floor(0.95 * this.deviceVPC * 100);
		let max = Math.ceil(1.05 * this.deviceVPC * 100);

		let voltages = [];
		let endVoltagesCounts = [];
		for(let i = min; i <= max; i++) {
			voltages.push(i/100);
			endVoltagesCounts.push(0);
		}
		min /= 100;
		max /= 100;

		let tableData = [];
		for (let dayIndex in this.endVoltageDailyDetails) {
			let currentDay = this.endVoltageDailyDetails[dayIndex];

			let data = currentDay.end_voltage_events_distribution;
			if(data) {
				for (let endVoltage in data) {

					let vpc = +endVoltage / this.device.config_info.number_of_cells;
					let endVoltageVal = +(vpc * 100).toFixed() / 100;

					for (let voltageIdx = 0; voltageIdx < voltages.length; voltageIdx++) {
						if (
							voltages[voltageIdx] == endVoltageVal ||
							(voltageIdx == 0 && endVoltageVal < voltages[voltageIdx]) ||
							(voltageIdx == voltages.length && endVoltageVal > voltages[voltageIdx])
						) {
							endVoltagesCounts[voltageIdx]++;
							tableData[voltageIdx] = tableData[voltageIdx] || [];
							for(let event of data[endVoltage]) {
								tableData[voltageIdx].push({
									sequence_id: event.sequence_id,
									timestamp: moment(event.timestamp*1000).utc().format('MM/DD/YYYY HH:mm:ss'),
									vpc: endVoltageVal
								})
							}
							break;
						}
					}
				}
			}
		}

		let chartData = [];
		let tableObjects = [];
		for(let i in endVoltagesCounts) {
			chartData.push([voltages[i], endVoltagesCounts[i], 'color: ' + this.getEndVoltageColors(voltages[i])]);
			tableObjects.push(tableData[i]);
		}
		this.endVoltageAllTableItems = tableObjects;
		this.endVoltageChartReady = true;
		return chartData;
	}

	private getUsageChartData() {
		let min = 0;
		let max = 96;

		let chartData = [];
		let chartDataWatt = [];

		for (let dayIndex in this.usageDailyDetails) {
			let currentDay = this.usageDailyDetails[dayIndex];

			if(currentDay.usage_summary) {
				for(let i = min; i < max; i++) {
					let mappedKey = this.getTimeByIndex(i, currentDay.date);
					chartData.push([mappedKey, Math.round(currentDay.usage_summary[i].a * 100) / 100]);
					chartDataWatt.push([mappedKey, Math.round(currentDay.usage_summary[i].w * 100) / 100]);
				}
			}
		}
		this.usageChartReady = true;
		return {amp: chartData, watt: chartDataWatt};
	}

	private getChargerUsageChartData() {
		const min = 0;
		const max = 96;

		const chartData = [];
		const chartDataWatt = [];

		for (let dayIndex in this.usageDailyDetails) {
			let currentDay = this.usageDailyDetails[dayIndex];

			if(currentDay.usage_charger_summary) {
				for(let i = min; i < max; i++) {
					const mappedKey = this.getTimeByIndex(i, currentDay.date);
					chartData.push([mappedKey, Math.round(currentDay.usage_charger_summary[i].a * 100) / 100]);
					chartDataWatt.push([mappedKey, Math.round(currentDay.usage_charger_summary[i].w * 100) / 100]);
				}
			}
		}
		this.usageChartReady = true;
		return {amp: chartData, watt: chartDataWatt};
	}

	private getTruckHoursChartData() {
		let chartData = [];
		for (let dayIndex in this.truckHoursDailyDetails) {

			const accurateSeconds = this.device.config_info.is_charglink ? 
				this.truckHoursDailyDetails[dayIndex].accurate_charge_seconds : 
				this.truckHoursDailyDetails[dayIndex].accurate_inuse_seconds;

			chartData.push([
				moment(+this.truckHoursDailyDetails[dayIndex].date*1000).format('l'),
				Math.round((accurateSeconds || 0) / 3600)
			]);
		}
		this.truckHoursChartReady = true;
		return chartData;
	}

	getTimeByIndex(idx, dayTime) {
		let zoneDiff = new Date().getTimezoneOffset() * -1 * 60 * 1000;
		return new Date((((idx * 15 * 60) + dayTime) * 1000) - zoneDiff);
	}

	onSelectEndVoltage(event) {
		this.endVoltageTableItems = [];
		if(!event)
			return;
		let tableItems = [];
		let selectedDevicesInfo = this.endVoltageAllTableItems[event.row];
		for(let item of selectedDevicesInfo) {
			let temp = {
				eventId: item.sequence_id,
				time: item.timestamp,
			};
			tableItems.push(temp);
		}
		this.endVoltageTableItems = tableItems;
		this.endVoltageModal.show();
	}

	exportIdleEvents() {
		let decimalPipe: DecimalPipe = new DecimalPipe("en-US");

		let alphabits = this.commonService.alphabetCharacters;
		let columnNames = [this.translate.instant('g.time'), this.translate.instant('perf_analytic.value_mins')];

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];

		let fileName = this.commonService.getCleanFileName(this.currentSite.name) +
						' - ' + this.device.config_info.truck_id +
						' - ' + this.translate.instant(this.chargLinkTranslationPath + 'site_performance.idle_events_report');
		let colLength = Object.keys(headerObj).length;
		['avg', 'total'].forEach((type)=>{
			let typeObj = header.slice();
			this.idleChartData[type].forEach((column) => {
				let vals = {};
				column.forEach((col, idx) => {
					if(idx >= colLength)
						return;
					let colId = alphabits[idx];
					if(idx == 1)
						col = decimalPipe.transform(col, '1.2-2');
					vals[colId] = col;
				});
				typeObj.push(vals);
			});
			let file = fileName + ' - ' + this.translate.instant('device_performance.'+type);
			new AngularCsv(typeObj, file);
		});
	}

	exportEndVoltage() {
		let alphabits = this.commonService.alphabetCharacters;
		let columnNames = [
      this.translate.instant('events.sequence_id'),
      this.translate.instant('events.timestamp'),
      this.translate.instant('event.vpc')
    ];

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];

		let fileName = this.commonService.getCleanFileName(this.currentSite.name) +
						' - ' + this.device.config_info.truck_id +
						' - ' + this.translate.instant(this.chargLinkTranslationPath + 'site_performance.end_voltage_report');
		let colLength = Object.keys(headerObj).length;
		let dataObj = header.slice();

		let chartData = [];
		let chartItems = this.endVoltageAllTableItems.slice();
		for(let dayItems of chartItems) {
			if(dayItems) {
				for(let item of dayItems) {
					chartData.push([item.sequence_id, item.timestamp, item.vpc]);
				}
			}
		}
		chartData = _.sortBy(chartData, 2).reverse();

		chartData.forEach((column) => {
			let vals = {};
			column.forEach((col, idx) => {
				if(idx >= colLength)
					return;
				let colId = alphabits[idx];
				vals[colId] = col;
			});
			dataObj.push(vals);
		});
		new AngularCsv(dataObj, fileName);
	}

	exportTruckHours() {
		let alphabits = this.commonService.alphabetCharacters;
		let columnNames = [this.translate.instant('g.date'), this.translate.instant('site_performance.time_report_title')];

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];

		let fileName = this.commonService.getCleanFileName(this.currentSite.name) +
						' - ' + this.device.config_info.truck_id +
						' - ' + this.translate.instant(this.chargLinkTranslationPath + 'device_performance.daily_truck_hours');
		let colLength = Object.keys(headerObj).length;
		let dataObj = header.slice();

		let chartData = this.truckHoursChartData.slice();
		// chartData = _.sortBy(chartData, 1).reverse();

		chartData.forEach((column) => {
			let vals = {};
			column.forEach((col, idx) => {
				if(idx >= colLength)
					return;
				let colId = alphabits[idx];
				vals[colId] = col;
			});
			dataObj.push(vals);
		});
		new AngularCsv(dataObj, fileName);
	}

	chartReadyFunct($event) {
		this.chartsReady[$event[0]] = $event[1];
	}

	onSelectionChanged(element) {
		this.chRef.detectChanges();
	}


	exportTotalUsage() {
		this.commonService.exportTotalUsageWidget({
			siteName: this.currentSite.name,
			startDate: this.usageFromDate,
			endDate: this.usageToDate,
			usageAmpData: this.usageChartDataAmp,
			usageWattData: this.usageChartDataWatt}, this.chargLinkTranslationPath);
	}

	handleStudyDeviceStartEndDate() {
		this.studyStartTime = new Date(this.studyInfo.start_study_time*1000);
		this.studyEndTime = this.studyInfo.is_done? new Date(this.studyInfo.end_study_time*1000) : new Date();

		this.deviceInstallationDate = this.studyStartTime;
		this.maxDate = this.studyInfo.is_done ? this.studyEndTime : new Date();
		const maxDate = this.studyInfo.is_done ? this.studyEndTime : new Date(new Date().setDate(new Date().getDate() - 1));

		this.idleTimesFromDate = this.studyStartTime
		this.idleTimesToDate = maxDate;

		this.endVoltageFromDate = this.studyStartTime
		this.endVoltageToDate = maxDate;

		this.usageFromDate = this.studyStartTime
		this.usageToDate = maxDate;

		this.truckHoursFromDate = this.studyStartTime
		this.truckHoursToDate = maxDate;
		this.fetchData();

		this.usageChartDataAmp = [];
		this.usageChartDataWatt = [];
		this.getDailyDetails('idleTimes');
		this.getDailyDetails('endVoltage');
		this.getDailyDetails('usage');
		this.getDailyDetails('truckHours');
	}
}
