import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams } from '../shared/loader/custom-httpParam';

@Injectable({
	providedIn: 'root'
})
export class DeviceManagementService {

	route = '/device/';

	eventTypes = {
		1: 'charge',
		2: 'idle',
		3: 'inuse',
	};

	batteryTypes = {
		1: 'lead',
		3: 'lithuim_ion',
		4: 'n/a',
	};

	shipmentSource =  {
		receive: 1,
		quality: 2,
	};



	currentAndVoltageRanges = {
		highCurrentRange: 10,
		lowCurrentRange: -10,
		highVoltageRange: 95,
		lowVoltageRange: 20,
	};

	constructor(
		private httpClient: HttpClient
	) { }

	getDeviceInfo(mac_address) {
		return this.httpClient.post(this.route + 'getDeviceInfo', {mac_address}, {
			observe: "body"
		});
	}

	searchDevices(searchString, searchBy, isPartial, searchDeleted, searchType='', isBulk=false) {
		return this.httpClient.post('/device/search/', {searchString, searchBy, isPartial, searchDeleted, searchType, isBulk}, {
			observe: "body"
		});
	}

	replaceDevice(sourceDeviceMac, destinationDeviceMac, options?) {
		return this.httpClient.post('/device/replaceDevice', {sourceDeviceMac, destinationDeviceMac, options}, {
			observe: "body"
		});
	}

	getDeviceOwners(deviceId, options?) {
		return this.httpClient.post('/deviceManagement/getDeviceOwners', {deviceId, options}, {
			observe: "body"
		});
	}

	reverseEvents(mac_address, startId, endId, studyId) {
		return this.httpClient.post(this.route + 'reverseEvents', {mac_address, startId, endId, studyId}, {
			observe: "body"
		});
	}

	reverseCalibration(mac_address, type) {
		return this.httpClient.post(this.route + 'reverseCalibration', {mac_address, type}, {
			observe: "body"
		});
	}

	reverseRT(mac_address, startId, endId) {
		return this.httpClient.post(this.route + 'reverseRT', {mac_address, startId, endId}, {
			observe: "body"
		});
	}

	setForceReadDebugDate(mac_address: string, force_read_debug_time: number) {
		return this.httpClient.post(this.route + 'setForceReadDebugDate', {mac_address, force_read_debug_time}, {
			observe: "body"
		});
	}

	setForceReadRTDate(mac_address: string, force_read_rt_time: number) {
		return this.httpClient.post(this.route + 'setForceReadRTDate', {mac_address, force_read_rt_time}, {
			observe: "body"
		});
	}

	saveDeviceOwners(deviceInfo, newCustomer, newSite, devicesIds, changeSetupDone = false, fromAdminPanel = false) {
		return this.httpClient.post('/deviceManagement/saveDeviceOwners', {deviceInfo, newCustomer, newSite, devicesIds, changeSetupDone, fromAdminPanel}, {
			observe: "body"
		});
	}

	sendManageDeviceRequest(deviceId, customerId, siteId, newCustomer, newSite, options?) {
		return this.httpClient.post('/deviceManagement/sendManageDeviceRequest', {deviceId, customerId, siteId, newCustomer, newSite, options}, {
			observe: "body"
		});
	}

	getPendingDeviceOwners(options?) {
		return this.httpClient.post('/deviceManagement/getPendingDeviceOwners', {options}, {
			observe: "body"
		});
	}

	getPendingCustomers(options?) {
		return this.httpClient.post('/deviceManagement/getPendingCustomers', {options}, {
			observe: "body"
		});
	}

	getPendingSites(options?) {
		return this.httpClient.post('/deviceManagement/getPendingSites', {options}, {
			observe: "body"
		});
	}

	getMoveRequests(options?) {
		return this.httpClient.post('/deviceManagement/getMoveRequests', {options}, {
			observe: "body"
		});
	}

	getReplacementRequests(options?) {
		return this.httpClient.post('/deviceManagement/getReplacementRequests', {options}, {
			observe: "body"
		});
	}

	approveCustomerRequest(customerId, customerInfo, options?) {
		return this.httpClient.post('/deviceManagement/approveCustomerRequest', {customerId, customerInfo, options}, {
			observe: "body"
		});
	}

	approveSiteRequest(siteId, siteInfo, options?) {
		return this.httpClient.post('/deviceManagement/approveSiteRequest', {siteId, siteInfo, options}, {
			observe: "body"
		});
	}

	approveMoveRequest(requestIds, options?) {
		return this.httpClient.post('/deviceManagement/approveMoveRequest', {requestIds, options}, {
			observe: "body"
		});
	}

	approveReplacementRequest(requestId, options?) {
		return this.httpClient.post('/deviceManagement/approveReplacementRequest', {requestId, options}, {
			observe: "body"
		});
	}

	mapToApprovedCustomer(customerId, mapToCustomerId) {
		return this.httpClient.post('/deviceManagement/mapToApprovedCustomer', {customerId, mapToCustomerId}, {
			observe: "body"
		});
	}

	mapToApprovedSite(siteId, mapToSiteId) {
		return this.httpClient.post('/deviceManagement/mapToApprovedSite', {siteId, mapToSiteId}, {
			observe: "body"
		});
	}

	rejectCustomerRequest(requestId, options?) {
		return this.httpClient.post('/deviceManagement/rejectCustomerRequest', {requestId, options}, {
			observe: "body"
		});
	}

	rejectSiteRequest(requestId, options?) {
		return this.httpClient.post('/deviceManagement/rejectSiteRequest', {requestId, options}, {
			observe: "body"
		});
	}

	rejectMoveRequest(requestId, options?) {
		return this.httpClient.post('/deviceManagement/rejectMoveRequest', {requestId, options}, {
			observe: "body"
		});
	}

	rejectReplacementRequest(requestId, options?) {
		return this.httpClient.post('/deviceManagement/rejectReplacementRequest', {requestId, options}, {
			observe: "body"
		});
	}

	getSiteLinkedDevices(siteId) {
		return this.httpClient.post('/deviceManagement/getSiteLinkedDevices', {siteId}, {
			observe: "body"
		});
	}

	getCustomerLinkedDevices(customerId) {
		return this.httpClient.post('/deviceManagement/getCustomerLinkedDevices', {customerId}, {
			observe: "body"
		});
	}

	getSiteDevices(siteID) {
		return this.httpClient.post(this.route + 'getSiteDevices', {siteID}, {
			observe: "body"
		});
	}

	addDevice(mac_address, serial_number, hw_version, site_id) {
		return this.httpClient.post(this.route + 'addDevice', {mac_address, serial_number, hw_version, site_id}, {
			observe: "body"
		});
	}

	updateDevice(mac_address, serial_number, hw_version, site_id, options?) {
		return this.httpClient.post(this.route + 'updateDevice', {mac_address, serial_number, hw_version, site_id, options}, {
			observe: "body"
		});
	}

	deleteDevices(mac_address, siteID, options?) {
		return this.httpClient.post(this.route + 'deleteDevices', {mac_address, siteID, options}, {
			observe: "body"
		});
	}

	hardDeleteDevice(mac_address, siteID, options?) {
		return this.httpClient.post(this.route + 'hardDeleteDevice', {mac_address, siteID, options}, {
			observe: "body"
		});
	}

	cmResetDeviceAfterDelete(mac_address) {
		return this.httpClient.post(this.route + 'cmResetDeviceAfterDelete', {mac_address}, {
			observe: "body"
		});
	}

	deleteDailyDetails(macAddress, siteID) {
		return this.httpClient.post(this.route + 'deleteDailyDetails', {macAddress, siteID}, {
			observe: "body"
		});
	}

	regenerateDailyDetails(macAddress, siteID) {
		return this.httpClient.post(this.route + 'regenerateDailyDetails', {macAddress, siteID}, {
			observe: "body"
		});
	}

	regenerateDevicePerformance(siteID) {
		return this.httpClient.post(this.route + 'regenerateDevicePerformance', {siteID}, {
			observe: "body"
		});
	}

	receiveDeviceShipment(deviceId: string, options = {}) {
		return this.httpClient.post(this.route + 'receiveDeviceShipment', { deviceId, options }, {
			observe: "body",
			params: new CustomHttpParams({ noUIBlock: true })
		});
	}

	checkCellularTestMode(deviceId, options = {}) {
		return this.httpClient.post(this.route + 'checkCellularTestMode', { deviceId, options }, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		})
	}

	checkCellularGpsStatus(deviceId, options = {}) {
		return this.httpClient.post(this.route + 'checkCellularGpsStatus', { deviceId, options }, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		})
	}

	shipOutDevices(formData: FormData) {
		return this.httpClient.post('/deviceManagement/shipOutDevices', formData, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		});
	}

	getShipmentsHistory() {
		return this.httpClient.get('/deviceManagement/getShipmentsHistory', {
			observe: "body"
		});
	}

	updateOrder(orderId: number, data: object) {
		return this.httpClient.put('/deviceManagement/updateShippingOutOrder', { orderId, data }, {
			observe: "body"
		});
	}

	checkShipmentQuality(deviceId, checkCellular = false, options = {}) {
		return this.httpClient.post(this.route + 'checkShipmentQuality', { deviceId, checkCellular, options }, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		});
	}

	checkDeviceFWStatus(deviceIds: string[], options = {}) {
		return this.httpClient.post(this.route + 'checkDeviceFWStatus', { deviceIds, options }, {
			observe: "body"
		});
	}

	prepareFileDownload(type, enc?) {
		return this.httpClient.post('/reporting/prepareFileDownload', {type, enc}, {
			observe: "body"
		});
	}
	scheduleReport(data) {
		return this.httpClient.post('/reporting/scheduleReport', {data}, {
			observe: "body",
		});
	}

	pushDeviceFW(mac_address, fwToPush) {
		return this.httpClient.post('/device/pushDeviceFW', {mac_address, fwToPush}, {
			observe: "body",
		});
	}

	getTestingFWFilesList(macAddress?: string) {
		return this.httpClient.post('/device/getTestingFWFilesList', {macAddress}, {
			observe: "body",
		});
	}

	deleteFWFile(file: string) {
		return this.httpClient.delete('/device/deleteFWFile', {
			observe: "body",
			params: {
				file
			}
		})
	}

	moveDeviceToRMA(mac_address: string, rma_no: string) {
		return this.httpClient.post(this.route + 'move-to-rma', { mac_address, rma_no });
	}

	getRmaDevices(options: any) {
		return this.httpClient.post(this.route + 'rma-devices', options)
	}

	exportRmaDevicesCSV(options: any) {
		return this.httpClient.post(this.route + 'exportRmaDevicesCSV', options)
	}

	moveFromRma(data: any) {
		return this.httpClient.post(this.route + 'move-from-rma', data);
	}

	createInitialHistory(options: any) {
		return this.httpClient.post('/device/createInitialHistory', { ...options }, {
			observe: "body",
		});
	}

	getReceiveShipmentHistory(source) {
		return this.httpClient.get('/device/getReceiveShipmentHistory', {
			observe: "body",
			params: {source}
		});
	}

	updateCmPoNumber(reportId: number, cmPoNumber: string) {
		return this.httpClient.post('/device/updateCmPoNumber', {reportId, cmPoNumber}, {
			observe: "body",
		});
	}

	getReceiveShipmentResult(reportId: any) {
		return this.httpClient.get('/device/getReceiveShipmentResult', {
			observe: "body",
			params: {
				reportId
			}
		});
	}

	downloadAttachment(files: string[], orderInfo: { id: number, po_number: string }) {
		return this.httpClient.post('/attachments/downloadShipmentAttachedFiles', { files, id: orderInfo.id, po_number: orderInfo.po_number }, {
			observe: "body",
		});
	}

	deleteAttachments(id: number, files: string[]) {
		return this.httpClient.post('/attachments/deleteShipmentAttachedFiles', { id, files }, {
			observe: "body",
		});
	}

	addAttachmentToShipmentHistory(form: FormData) {
		return this.httpClient.post(`attachments/addAttachmentToShipmentHistory`, form, {
			observe: "body",
		});
	}

	setForceDebugRecordNow(mac_address: string) {
		return this.httpClient.post(this.route +'setForceReadDebugRecordsNow', {mac_address}, {
			observe: "body"
		})
	}

	getUpdateFirmwareLog(options: any) {
		return this.httpClient.post('/deviceManagement/updateFirmwareLog', { options }, {
			observe: "body"
		});
	}

	getLiveQuickView(deviceId: string) {
		return this.httpClient.post('/device/getLiveQuickView', {deviceId});
	}

	regenerateDeviceAnalyticsReport(macAddress: string, siteID: number) {
		return this.httpClient.post(this.route + 'regenerateDeviceAnalyticsReport', { macAddress, siteID }, {
			observe: "body"
		});
	}

	checkSmartRebatesShippingOption() {
		return this.httpClient.get('/site/checkSmartRebatesShippingOption', {
			observe: "body"
		});
	}

	searchDevicesEventsByAh(model) {
		return this.httpClient.post('/reporting/searchDevicesEventsByAh', { model }, {
			observe: "body"
		});
	}

	generateReceivedShipmentHistoryReport(dateRange: {to: number, from: number}, source: number, reportIds: number[]) {
		return this.httpClient.post(this.route + 'generateReceivedShipmentHistoryReport', { dateRange, source, reportIds }, {
			observe: "body"
		});
	}

	cancleSearchAhEventsProcess() {
		return this.httpClient.delete('/reporting/cancleSearchAhEventsProcess', {
			observe: "body"
		});
	}

	getSearchProcessStatus() {
		return this.httpClient.get('/reporting/getSearchProcessStatus', {
			observe: 'body'
		});
	}

	searchReceiveShipmentHistoryDevices(deviceId: string, searchBy: string) {
		return this.httpClient.get('/device/searchReceiveShipmentHistoryDevices', {
			observe: "body",
			params: {
				deviceId,
				searchBy
			}
		});
	}

	getDevicesWithoutLocation(customers: null | number[], devices: string[] | null[]) {
		return this.httpClient.post(`deviceManagement/get-devices-without-location`, { customers, devices }, {
			observe: "body"
		});
	}

	disableXtraFile(macAddresses: string[] | null[]) {
		return this.httpClient.post(`deviceManagement/devices-disable-xtra-file`, { mac_addresses: macAddresses }, {
			observe: "body"
		});
	}

	configurePSDevices(macAddresses: string[]) {
		return this.httpClient.post(`deviceManagement/configure-ps-devices`, { mac_addresses: macAddresses }, {
			observe: "body"
		});
	}
}
