<div class="date-range white-box main-black p-4 border-radius-bottom">
	<div class="border-bottom p-3">
		<h2>{{ 'admin.mobile_app_version' | translate }}</h2>
	</div>
	<div class="px-5 py-4">
		<form #appVersionForm="ngForm" (ngSubmit)="saveAppVersionSettings()">
			<div class="row mt-2 px-6 align-items-center">
				<div class="col-md-4">
					<strong for="changed_by">{{ 'mobile_version.changed_by' | translate }}</strong>
				</div>
				<div class="col-md-7">
					<input
						type="text"
						class="form-control"
						name="changed_by"
						[disabled]="true"
						[(ngModel)]="appVersionsInfo.user_name" 
					/>
				</div>
			</div>
			<div class="row mt-2 px-6 align-items-center">
				<div class="col-md-4">
					<strong for="created_at">{{ 'mobile_version.last_change_time' | translate }}</strong>
				</div>
				<div class="col-md-7">
					<input
						type="text"
						class="form-control"
						name="created_at"
						[disabled]="true"
						[(ngModel)]="appVersionsInfo.created_at" 
					/>
				</div>
			</div>
			<div class="row mt-2 px-6 align-items-center">
				<div class="col-md-4">
					<strong for="latest_app_version">{{ 'mobile_version.latest_app_version' | translate }}</strong>
				</div>
				<div class="col-md-7">
					<input
						type="text"
						class="form-control"
						name="latest_app_version"
						[(ngModel)]="appVersionsInfo.latest_app_version"
						[ngClass]="{'invalid-input': !appVersionsInfo.latest_app_version}"
					/>
				</div>
			</div>
			<div class="row mt-2 px-6 align-items-center">
				<div class="col-md-4">
					<strong for="min_app_version">{{ 'mobile_version.min_app_version' | translate }}</strong>
				</div>
				<div class="col-md-7">
					<input
						type="text"
						class="form-control"
						name="min_app_version"
						[(ngModel)]="appVersionsInfo.min_app_version"
						[ngClass]="{'invalid-input': !appVersionsInfo.min_app_version}"
					/>
				</div>
			</div>
				<br />
			<div class="mt-3">
				<button
					type="submit"
					class="btn btn-primary"
				>
					{{ 'g.submit' | translate }}
				</button>
			</div>
		</form>
	</div>
</div>