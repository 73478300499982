import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DeviceManagementService } from '../device-management.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';

@Component({
	selector: 'app-manage-device-request',
	templateUrl: './manage-device-request.component.html',
	styleUrls: ['./manage-device-request.component.css']
})
export class ManageDeviceRequestComponent implements OnInit {
	@Input() devicesIds: any;
	@Input() render: string = "full";
	@Output() deviceOwnerSaved = new EventEmitter<void>();
	deviceInfo: any = {};
	customers: any = [];
	sites: any = [];
	customer: any = {};
	site: any = {};
	serialNumbers = [];
	powerStudySiteObj: any = {};
	newCustomer	= {id: 0, customer_name: this.translateService.instant('site.new_customer'), sites: [{'id': 0, 'name': this.translateService.instant('site.new_site')}]};
	newSite		= {id: 0, name: this.translateService.instant('site.new_site')};
	originalDeviceSiteId: number;

	constructor(
		private deviceManagementService: DeviceManagementService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private navigation: NavigationService,
		private sideMenuService: SideMenuService,
		private chRef: ChangeDetectorRef,
		private translateService: TranslateService,
		public sitesService: SitesService,
		public usersService: UsersService,
		private el: ElementRef,
		private renderer: Renderer2,
		private commonService: CommonService,
		private enterpriseService: EnterpriseService
	) { }

	ngOnInit() {
		if(this.render == "full") {
			this.sideMenuService.hide();
		}
		this.route.params.subscribe(param => {
			this.devicesIds = param.deviceId || '';
			this.devicesIds = this.devicesIds.split(',');
			this.deviceManagementService.getDeviceOwners(this.devicesIds).subscribe(
				data => this.init(data)
			);
		});
		this.site.installation_date = new Date(new Date().setHours(0, 0, 0, 0));
	}

	init(response) {
		this.serialNumbers = response.devices.map(device => device.serial_number);
		this.deviceInfo = response.devices[0];
		this.originalDeviceSiteId = this.deviceInfo.site_id;
		this.powerStudySiteObj = response.power_studies_site;
		this.customers  = response.customersAndSites.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
		this.prepareDeviceOwners();
	}

	prepareDeviceOwners() {
		this.deviceManagementService.getPendingDeviceOwners().subscribe((res: any) => {
			const pendingCustomers = res.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
			this.customers = [this.newCustomer].concat(pendingCustomers, this.customers);

			let deviceSiteCustomerId = 0;

			for(let customer of this.customers){
				customer.sites.unshift(this.newSite);

				for(let site of customer.sites){
					if(site.id == this.deviceInfo.site_id){
						deviceSiteCustomerId = customer.id;

						this.sites = customer.sites.filter((site) => site.is_special !== true).sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);
						this.deviceInfo.customer_id = customer.id;
						this.deviceInfo.oldCustomerId = customer.id;
					}
				}
			}
			this.deviceInfo.oldSiteId = this.deviceInfo.site_id;

			this.getCustomerSites(deviceSiteCustomerId);
		});
	}

	handlePowerStudySites(customer) {
		const customerStudySite = customer.sites.find(site => site.name === this.commonService.powerStudiesDevicesSiteName) || this.powerStudySiteObj;

		this.sites = [customerStudySite];
		this.site = customerStudySite;
		this.deviceInfo.oldSiteId = this.deviceInfo.site_id;
		this.deviceInfo.site_id = this.site.id;
	}

	getCustomerSites(customerId) {
		this.sites = [];
		this.site.customer_enterprises = [];
		this.deviceInfo.site_id = this.deviceInfo.is_study_device? this.deviceInfo.site_id : 0;
		if(!customerId){
			this.sites = [this.newSite];
			return;
		}
		const hasManagementAccess = this.usersService.hasAccessFunction('device_management');
		const hasManagementSpecialSiteAccess = this.usersService.hasAccessFunction('special_site_device_management');

		for(let customer of this.customers){
			if(customer.id == customerId){
				if (this.deviceInfo.is_study_device)
					return this.handlePowerStudySites(customer);

				if (!this.usersService.hasAccessFunction('super_admin', 'write')) {
					customer.sites = customer.sites.filter((site: any) =>
						(!site.is_special || (site.is_special && hasManagementAccess && hasManagementSpecialSiteAccess)));
				}
				this.sites = customer.sites.sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);
				break;
			}
		}
	};

	sendManageDeviceRequest() {
		let tempSite = Object.assign({}, this.site);
		let insDate = tempSite.installation_date ? new Date(tempSite.installation_date) : new Date();
		tempSite.installation_date = Math.floor((-insDate.getTimezoneOffset() * 60000 + insDate.getTime()) / 1000);
		if(this.deviceInfo.site_id) {
			delete tempSite.installation_date;
		}

		this.deviceManagementService.sendManageDeviceRequest(
			this.devicesIds,
			this.deviceInfo.customer_id,
			this.deviceInfo.site_id,
			this.customer,
			tempSite,
			{remove_existing: true}
		).subscribe((res: any)=>{
			if (res.api_status == 2) {
				const form = this.el.nativeElement;
				const ele = form.querySelectorAll('[name=name]');
				if(ele && ele[0])
					this.renderer.addClass(ele[0], 'invalid-input');

				return this.notificationMessage.setMessage('translate|site.reserved_site_name', {clearOnXTimeNavigate: 1});
			}

			this.notificationMessage.setMessage('globalSuccessMsg');
			this.goBack();
		});
	};

	updateSiteCoords(coords) {
		this.site.latitude = coords.lat;
		this.site.longitude = coords.lng;
		this.site.address = coords.address;
		this.site.country_id = coords.country;
		this.site.state_id = coords.state;
		this.chRef.detectChanges();
	}

	goBack(){
		return this.navigation.goBack('/');
	}
}
