import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from '../site-dashboard/device/device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as _ from 'underscore';
@Component({
	selector: 'app-usage-time-widget',
	templateUrl: './usage-time-widget.component.html',
	styleUrls: ['./usage-time-widget.component.css']
})
export class UsageTimeWidgetComponent {
	parentPages = {
		device: 'device',
		site: 'site'
	}

	@Input() minDeviceDate: any = null;
	@Input() maxDeviceDate: any = null;
	@Input() devices: any = [];
	@Input() tags: any[] = [];
	@Input() site: any = null;
	@Input() studyInfo: any = {};
	@Input() parentPage: string = this.parentPages.device;
	@Input() options: { hasTruckTypeFilter, hasYearFilter } = {
		hasTruckTypeFilter: false,
		hasYearFilter: false,
	};

	invalidDateRange: boolean = false;
	noData :boolean = false;
	hideChart: boolean = false;

	tooltip = 'usage_time_device';
	chargLinkTranslationPath: string = "";

	data = [];
	macAddresses = [];
	selectedTagNames = [];

	// device filters
	ddList = {
		truckTypes: [],
		truckYearsList: [],
	};
	truckTypesMapper = {};
	selectedYear = moment().utc().get('year');

	selectedTruckYear = '';
	selectedTruckType = '';

	selectedSites = [];
	selectedTags = [];

	// widget data
	eventTime = {
		charge_off_time: 0,
		idle_time: 0,
		lift_time: 0,
		travel_time: 0,
		dead_man_time: 0
	}

	events = ['charge_off_time', 'idle_time', 'dead_man_time']
	eventsVisibility = {
		charge_off_time: true,
		idle_time: true,
		dead_man_time: true,
	}

	eventsColors = {
		charge_off_time: '#8AD1C2',
		idle_time: '#9F8AD1',
		lift_time: '#D18A99',
		travel_time: '#FFD700',
		dead_man_time: '#FF6347'
	}

	chartOptions = {
		title: this.translate.instant('perf_analytic.usage_time'),
		height: 650,
		legend: { position: 'right', textStyle: { fontSize: 16 }, alignment: 'center'},
		tooltip: {
			trigger: 'hover',
			isHtml: false,
			text: 'percentage',
			showColorCode: true
		},
		slices: {
			0: { color: this.eventsColors.charge_off_time },
			1: { color: this.eventsColors.idle_time },
			2: { color: this.eventsColors.lift_time },
			3: { color: this.eventsColors.travel_time },
			4: { color: this.eventsColors.dead_man_time }
		},
		pieSliceText: 'percentage',
		pieSliceTextStyle: {
			fontSize: 12
		},
	};

	// date data
	deviceInstallationDate: any = moment(0).toDate();
	fromDate: Date = moment().subtract(3, 'months').toDate();
	toDate: Date = moment().subtract(1, 'day').toDate();
	maxDate = moment().subtract(1, 'day').toDate();
	hideDateSelector: boolean = false;

	constructor(
		private translate: TranslateService,
		private common: CommonService,
		private deviceService: DeviceService,
		private notificationMessage: NotificationMessageService
	){}

	ngOnInit() {
		if(this.parentPage == this.parentPages.site)
			this.tooltip = 'usage_time_site';

		this.ddList.truckYearsList = [];

		for(let item of ['all', 'new', 'old']) {
			this.ddList.truckYearsList.push({
				value: item == 'all' ? '' : item,
				label: this.translate.instant('site_performance.truck_year_'+item)
			});
		}

		if (this.maxDeviceDate && this.maxDeviceDate < this.maxDate) {
			this.maxDate = this.maxDeviceDate;
			this.toDate = this.maxDeviceDate;
		}
	}

	DatesChanged() {
		this.invalidDateRange = false;
		if(moment(this.fromDate).unix() > moment(this.toDate).unix())
			return this.invalidDateRange = true;

		this.getUsageTime();
	}

	onSelectedTagsChanges(event) {
		this.selectedTags = event;
		this.selectedTagNames = [];

		for(let tagId of event) {
			let tag = _.findWhere(this.tags, {id: tagId})
			if(tag)
				this.selectedTagNames.push(tag.tag_name)
		}

		this.getUsageTime();
	}

	getUsageTime() {
		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let from:any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));

		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();

		if (!this.macAddresses.length)
			return this.hideChart = true;

		this.hideChart = false;
		const devicesFilters = {
			truckYear: this.selectedTruckYear,
			truckType: this.truckTypesMapper[this.selectedTruckType],
			truckTag: this.selectedTags,
			parentPage: this.parentPage
		}
		let macAddress = null;
		if(this.parentPage == this.parentPages.device)
			macAddress = this.macAddresses[0];

		this.deviceService.getDevicesUsageTime(macAddress, this.site?.id, from, to, devicesFilters).subscribe((res: any) => {
			if(res?.api_status)
				this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });

			const devicesCount = res.devices_count;
			const inuseDuration = res.inuse_duration;
			delete res.devices_count;
			delete res.inuse_duration;

			this.eventTime = res;
			const totalDuration = devicesCount * (to - from + 1);
			this.eventTime.charge_off_time = totalDuration - this.eventTime.idle_time - inuseDuration;
			this.eventTime.charge_off_time = this.eventTime.charge_off_time > 0 ? this.eventTime.charge_off_time : 0;

			this.formateUsageTimeData();
		})
	}

	ngOnChanges(changes) {
		this.deviceInstallationDate = moment(0).toDate();
		this.fromDate = moment().subtract(3, 'months').toDate();
		this.maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
		this.hideDateSelector = false;
		if (this.studyInfo?.id)
			this.handleStudyDeviceStartEndDate();

		if(changes.devices || changes.site) {
			if (this.maxDeviceDate && this.maxDeviceDate < this.maxDate) {
				this.maxDate = this.maxDeviceDate;
				this.toDate = this.maxDeviceDate;
			}

			// site performance
			if (this.parentPage == this.parentPages.site) {
				this.macAddresses = this.devices.filter(device => !device.is_charglink).map(device => device.mac_address);
				this.updateInstallationDate(moment(this.site.installation_date || 0).unix());
				this.prepareTruckTypeOptions();
				this.getUsageTime();
			}
			// device performance
			else if (this.parentPage == this.parentPages.device) {
				if (!this.devices[0].config_info.is_charglink)
					this.macAddresses = [this.devices[0].config_info.mac_address];

				this.updateInstallationDate((this.devices[0].config_info.installation_date || 0));
				this.getUsageTime();
			}
		}
	}

	updateInstallationDate(date) {
		if (date > 0)
			this.deviceInstallationDate = moment(date*1000).toDate();

		if (this.minDeviceDate && moment(this.minDeviceDate).unix() > moment(this.deviceInstallationDate).unix())
			this.deviceInstallationDate = this.minDeviceDate;

		if(moment(this.fromDate).unix() < moment(this.deviceInstallationDate).unix())
			this.fromDate = this.deviceInstallationDate;
	}

	prepareTruckTypeOptions() {
		const devices = this.devices.filter(device => !device.is_charglink);
		let truckTypesOptions: any = [
			{id: '', text: this.translate.instant('site_performance.all_truck_types')}
		];

		_.uniq(_.pluck(devices, 'truck_type')).forEach((truckType) => {
			const truckTypeKey = truckType.replace(/[^\w]/gi, '_');
			this.truckTypesMapper[truckTypeKey] = truckType;
			const record: {id: number, text: string} = {id: truckTypeKey == '' ? '%' : truckTypeKey, text: truckType};
			truckTypesOptions.push(record);
		});

		this.ddList.truckTypes = truckTypesOptions;
	}

	formateUsageTimeData() {
		let chartData = [];
		const slicesColors: any = {};
		let count = 0;
		let noData = true;

		for (let key of Object.keys(this.eventTime)) {
			if (key in this.eventsVisibility && this.eventsVisibility[key] == false)
				continue;

			if (this.eventTime[key])
				noData = false;

			slicesColors[count] = {color: this.eventsColors[key]};
			count++;
			const eventTime = [`${this.translate.instant(`usage_time_chart.${key}`)} (${this.common.secondsToElapsedTime(this.eventTime[key])})`, this.eventTime[key]];
			chartData.push(eventTime);
		}

		this.chartOptions.slices = slicesColors;
		this.data = chartData;
		this.noData = noData;
	}

	toggleEventsVisibility(event) {
		this.eventsVisibility[event] = !this.eventsVisibility[event];
		this.formateUsageTimeData();
	}

	handleStudyDeviceStartEndDate() {
		this.deviceInstallationDate = new Date(this.studyInfo.start_study_time*1000);
		this.hideDateSelector = !this.studyInfo.is_done && moment().isSame(this.deviceInstallationDate, 'day');
		this.maxDate = this.studyInfo.is_done ? new Date(this.studyInfo.end_study_time*1000) : new Date(new Date().setDate(new Date().getDate() - 1));
		this.fromDate = this.deviceInstallationDate;
		this.toDate = this.maxDate;
		this.getUsageTime();
	}
}
