<app-modal #saveOptionsModal [title]="'settings.save_device_settings' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'settings.apply_changes' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="saveDeviceSettings('cancel')">{{'g.cancel' | translate}}</button>
		<button class="btn btn-warning mr-2" (click)="saveDeviceSettings('queue')" *ngIf="currentUser.is_admin">{{'settings.queue_it' | translate}}</button>
		<button class="btn btn-warning mr-2" (click)="saveDeviceSettings('queue_and_wait')">{{'settings.queue_and_wait' | translate}}</button>
		<button class="btn btn-primary" (click)="saveDeviceSettings('direct')">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<app-modal #deviceWithoutPasswordModal [hasConfirmButton]="false">
	<div class="alert alert-warning" role="alert">
		{{'settings.device_without_password' | translate}}.
	</div>
</app-modal>
<app-modal #warningsModal [hasConfirmButton]="false" [title]="'g.warnings' | translate" *ngIf="deviceWarnings.length">
	<div class="alert alert-warning" role="alert" *ngFor="let warning of deviceWarnings">
		{{'warnings.'+warning | translate}}
	</div>
</app-modal>

<div [ngSwitch]="tab" class="bg-white">
	<div class="d-flex justify-content-around page-cards card-container" #cardContainer>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'info' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'info'}" *ngIf="cardsVisibility.info">
			<img src="/sctportal/images/settings/device_info.png">
			<div class="font-weight-bold">
				{{'devices.device_info' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'deviceSettings' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'deviceSettings'}" *ngIf="cardsVisibility.deviceSettings">
			<img src="/sctportal/images/settings/device_settings.png">
			<div class="font-weight-bold">
				{{'g.device_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'batterySettings' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'batterySettings'}" *ngIf="cardsVisibility.batterySettings && !device.is_charglink">
			<img src="/sctportal/images/settings/battery_settings.png">
			<div class="font-weight-bold">
				{{'g.battery_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'cal' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'cal'}" *ngIf="cardsVisibility.cal">
			<img src="/sctportal/images/settings/calibration.png">
			<div class="font-weight-bold">
				{{'g.calibration' | translate}}
			</div>
		</a>
		<a class="px-4 py-5 pointer" *ngIf="usersService.hasAccessFunction('official_reporting')"
			[routerLink]="getSettingUrl()" [queryParams]="{ tab: 'official-reporting' }" (click)="commonUtil.handleLinkClickEvents($event)"
			[ngClass]="{'active': tab == 'official-reporting'}"
		>
			<fa-icon icon="file-contract" size="3x"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'action_log.official_reporting' | translate}}
			</div>
		</a>
		<a class="px-4 py-5 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'connectivityFrequency' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'connectivityFrequency'}" *ngIf="usersService.userHasNOCAccess()">
			<fa-icon [icon]="'satellite-dish'" size="3x" class="satellite-dish"></fa-icon>
			<div class="font-weight-bold pt-3">
				{{'g.connectivity_frequency' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'networkingSettings' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'networkingSettings'}" *ngIf="cardsVisibility.networkingSettings">
			<img src="/sctportal/images/settings/networking_settings.png">
			<div class="font-weight-bold">
				{{'g.networking_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'networking' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'networking'}" *ngIf="cardsVisibility.networking">
			<img src="/sctportal/images/settings/wifi.png">
			<div class="font-weight-bold pt-3">
				{{'g.networking' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'billedKWHrsSettings' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'billedKWHrsSettings'}" *ngIf="cardsVisibility.billedKWHrsSettings">
			<img src="/sctportal/images/billed_whrs.png">
			<div class="font-weight-bold">
				{{'g.billed_kwhrs_settings' | translate}}
			</div>
		</a>
		<a class="p-4 pointer" [routerLink]="getSettingUrl()" [queryParams]="{ tab: 'maintenanceSchedule' }" (click)="commonUtil.handleLinkClickEvents($event)" [ngClass]="{'active': tab == 'maintenanceSchedule'}" *ngIf="hasMaintenance && !isChargLinkDevice">
			<img src="/sctportal/images/settings/maintenance_schedule.png">
			<div class="font-weight-bold">
				{{'g.maintenance_schedule' | translate}}
			</div>
		</a>
	</div>
	<div class="px-5 py-4" *ngIf="permissionExists()">
		<div *ngSwitchCase="'info'">
			<div class="alert alert-primary">{{(chargLinkTranslationPath + 'help_section.info') | translate}}</div>
			<div *ngIf="device.is_blocked_connection && usersService.userHasNOCAccess(enterprisePermissions)" class="alert alert-danger">
				{{ 'devices.block_connection_until' | translate}} {{ (device.blocked_connection_until * 1000) | date:'MM/dd/yyyy hh:mm:ss a':'UTC/GMT' }}
			</div>
			<sct-form
				[data]="device"
				[name]="'infoForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="infoFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(formSubmitted)="checkChanges($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'deviceSettings'">
			<sct-form
				[data]="device"
				[name]="'deviceSettingsForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="deviceSettingsFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(formSubmitted)="checkChanges($event)"
				(dropdownChanged)="onDropdownChanged($event)"
				(onBeforeSubmit)="onBeforeSubmit($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'batterySettings'">
			<sct-form
				[data]="device"
				[name]="'batterySettingsForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="batterySettingsFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(dropdownChanged)="onDropdownChanged($event)"
				(formSubmitted)="checkChanges($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'cal'">
			<sct-form
				[data]="device"
				[name]="'calForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="calFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(formSubmitted)="checkChanges($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
			<!-- Adding multi point Calbiration -->
			<div *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'calibration')">
				<div class="performance-values white-box main-black p-3 border-radius-bottom">
					<div class="preperty-values row" >
						<div class="col-4 p-0">
							<div *ngIf="device.mp_cal_status != null">
								<div class="p-2">{{'multi_point_calibration.mp_cal_status' | translate}}</div>
								<div class="p-2">{{'multi_point_calibration.mp_status.'+device.mp_cal_status | translate}}</div>
							</div>
							<div *ngIf="device.mp_cal_flags != null">
								<div class="p-2">{{('multi_point_calibration.multi_point_flags') | translate}}</div>
								<div class="p-2">{{device.mp_cal_flags}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="d-flex justify-content white-box border-radius-bottom">
					<div class="col" *ngIf="showMultiPointCal(device.mp_cal_high_range)">
						<div class="preperty-values">
							<h4>{{'multi_point_calibration.high_range' |translate}}</h4>
							<div class="centered-values mt-2">
								<div *ngFor="let field of device.mp_cal_high_range | keys">
									<div class="p-4">{{field | translate}}</div>
									<div class="p-4">
										<div><span>{{'multi_point_calibration.adc_val' | translate}}</span>: {{device.mp_cal_high_range[field].adc_val}}</div>
										<div class="mt-1 border-top"><span>{{'multi_point_calibration.current_val' | translate}}</span>: {{device.mp_cal_high_range[field].current_val}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col" *ngIf="showMultiPointCal(device.mp_cal_low_range)">
						<div class="preperty-values">
							<h4>{{'multi_point_calibration.low_range' | translate}}</h4>
							<div class="centered-values mt-2">
								<div *ngFor="let field of device.mp_cal_low_range | keys">
									<div class="p-4">{{field | translate}}</div>
									<div class="p-4">
										<div><span>{{'multi_point_calibration.adc_val' | translate}}</span>: {{device.mp_cal_low_range[field].adc_val}}</div>
										<div class="mt-1 border-top"><span>{{'multi_point_calibration.current_val' | translate}}</span>: {{device.mp_cal_low_range[field].current_val}}</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>
		</div>
		<div *ngSwitchCase="'networkingSettings'">
			<sct-form
				[data]="device"
				[name]="'networkingSettingsForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="networkingSettingsFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(dropdownChanged)="onDropdownChanged($event)"
				(formSubmitted)="checkChanges($event)"
				(onBeforeSubmit)="onBeforeSubmit($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'official-reporting'">
			<app-official-report [device]="device"  [config_info]="config_info"></app-official-report>
		</div>
		<div *ngSwitchCase="'connectivityFrequency'">
			<app-connectivity-frequency [macAddress]="device.mac_address"></app-connectivity-frequency>
		</div>
		<div *ngSwitchCase="'networking'">
			<div
				class="row col-12 mt-3"
				*ngIf="!multiDevices && device.connected && device.enable_wifi_sta && usersService.hasAccessPermission(enterprisePermissions, 'online_network.ssid', 'write')"
			>
				<button class="btn btn-light border-success text-success" (click)="scanWifiNetworks()">{{'settings.available_networks' | translate}}</button>
			</div>
			<sct-form
				[data]="device"
				[name]="'networkingForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="networkingFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(formSubmitted)="checkChanges($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'billedKWHrsSettings'">
			<sct-form
				[data]="device"
				[name]="'billedKWHrsSettingsForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="billedKWHrsSettingsFormFields"
				[changesStack]="changesStack"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(dropdownChanged)="onDropdownChanged($event)"
				(formSubmitted)="checkChanges($event)"
				(removeChangeStack)="removeChangeStack($event)"
			></sct-form>
		</div>
		<div *ngSwitchCase="'maintenanceSchedule'">
			<sct-form
				[data]="maintenanceSchedule"
				[name]="'maintenanceScheduleForm'"
				[permissions]="enterprisePermissions.permission"
				[fields]="maintenanceScheduleFormFields"
				[showSubmitButton]="showSaveButton(tab)"
				[highlightEmpty]="multiDevices"
				(formSubmitted)="checkMaintenanceChanges($event)"
			></sct-form>
		</div>
	</div>
</div>
<app-modal #availableWifiNetworksModal title="{{'settings.available_networks' | translate}}" size="lg" [hasConfirmButton]="false">
	<div class="scroll-x-auto">
		<table class="table table-striped mt-3">
			<thead>
				<tr>
					<th>{{'settings.ssid' | translate}}</th>
					<th >{{'settings.bssid' | translate}}</th>
					<th >{{'settings.channel' | translate}}</th>
					<th>{{'settings.signal_strength' | translate}}</th>
					<th >{{'settings.encryption' | translate}}</th>
				</tr>
			</thead>
			<tbody class="wifi-networks">
				<tr *ngFor="let network of availableWifiNetworks" (click)="selectWifiNetwork(network)">
					<td>{{network.ssid}}</td>
					<td >{{network.bssid}}</td>
					<td >{{network.channel}}</td>
					<td>{{network.signalStrength}}</td>
					<td >{{network.authMode}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<button class="btn text-white main-blue-bg col-12" (click)="scanWifiNetworks(true)">{{'g.refresh' | translate}}</button>
</app-modal>
<app-modal #selectWifiNetworksModal title="{{'settings.select_wifi_network' | translate}}" closeBtnText="{{'g.no' | translate}}" confirmBtnText="{{'g.yes' | translate}}">
	<div>
		{{'settings.connect_network_with_bssid' | translate:{ssid: selectedWifiNetwork.ssid} }}
	</div>
</app-modal>

<app-modal #deleteChangeStack [title]="'g.confirm_action' | translate">
	<p>
		{{(confirmDeleteStack? 'settings.remove_stack_non_related_user_msg' : 'settings.remove_changes_stack_title_msg')| translate }}
	</p>
</app-modal>

<app-modal #restartDeviceConfirm [title]="'devices.restart_required' | translate" [confirmBtnText]="'g.yes'" [closeBtnText]="'g.no'">
	<p>
		{{'devices.restart_device_confirm_message'| translate }}
	</p>
	<ul>
		<li *ngFor="let field of restartDeviceChangedFields">{{('devices.' + field) | translate}}</li>
	</ul>
</app-modal>
