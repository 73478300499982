<div class="row mt-3 mb-3 col-12 justify-content-md-center">
	<div class="col-md-12">
		<h2>{{'admin.force_read_debug' | translate}}</h2>
		<div class="form-field mb-2">
			<label for="force_read_debug_time">{{'force_read_debug.sub_title' | translate}}</label>
			<input type="number" class="form-control col-4" [(ngModel)]="force_read_debug_time" [max]="maxDebugHours" >
		</div>
		<h6 *ngIf="forceReadDebugUntil" class="mt-2">{{'force_read_debug.reading_time_until' | translate}}: <b>{{forceReadDebugUntil}} ({{'g.utc' | translate}})</b></h6>
		<div class="my-4">
			<button type="submit" class="btn btn-primary" [disabled]="force_read_debug_time == null" (click)="saveDebugDate()">{{'g.submit' | translate}}</button>
			<button *ngIf="usersService.hasAccessFunction('super_admin', 'write')" type="submit" class="btn btn-primary ml-4" (click)="forceReadDebugNow()">{{'force_read_debug.force_read_debug_now' | translate}}</button>
		</div>
	</div>
</div>

<div class="white-box p-4 border-radius-bottom">
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getDebugRecords($event)"
	></sct-custom-table>
</div>
