<div>
	<div class="d-flex justify-content-between mb-2 px-2">
		<h2>{{'connectivity_verification.connectivity_verification_header' | translate}}</h2>
	</div>
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal [title]="modalTitle" #devicesInfoModel  [hasConfirmButton]="false" [hasCloseButton]="false" size="xl">
	<div class="d-flex justify-content-end">
		<button class="btn btn-primary mb-2" (click)="exportUniqueDevicesFile()">{{'g.export' | translate}}</button>
	</div>
	<table class="table table-striped">
		<thead>
			<tr class="text-center">
				<th>{{'g.devices' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr class="text-center" *ngFor="let macAddress of devicesData">
				<td>
					<a [routerLink]="devicesInfo[macAddress].serial_number == 'device_deleted'? null: ['/', devicesInfo[macAddress].customerId, devicesInfo[macAddress].siteId, macAddress, 'performance']">
						{{(devicesInfo[macAddress].serial_number == 'device_deleted'? macAddress : devicesInfo[macAddress].serial_number)}}
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</app-modal>

<app-modal [title]="modalTitle" #sitesInfoModel  [hasConfirmButton]="false" [hasCloseButton]="false" size="xl">
	<table class="table table-striped">
		<thead>
			<tr class="text-center">
				<th>{{'site.sites' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr class="text-center" *ngFor="let site of sitesData">
				<td>
					<a [routerLink]="sitesInfo[site].siteName == 'site_deleted'? null : ['/', sitesInfo[site].customerId, site]">
						{{(sitesInfo[site].siteName == 'site_deleted'? "connectivity_verification." + sitesInfo[site].siteName  : sitesInfo[site].customerName + ' | ' + sitesInfo[site].siteName ) | translate}}
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</app-modal>
