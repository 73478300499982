import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from '../users.service';
import { SideMenuService } from '../../shared/side-menu/side-menu.service';
import { CaptchaComponent } from '../../users/captcha/captcha.component';
import { LoaderInterceptorService } from '../../shared/loader/loader-interceptor.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

	@ViewChild('captchaComponent') captchaComponent: CaptchaComponent;
	@Input() userId = 0;
	@Input() options = {
		hideTitle: false,
		forced: false
	};

	form: any = {};
	captchaEnabled: boolean = false;
	submitted = false;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private loader: LoaderInterceptorService,
		private notificationMessage: NotificationMessageService,
		private navigation: NavigationService,
		private translateService: TranslateService
	) {
		sideMenuService.hide();
	}

	ngOnInit() {
		this.captchaEnabled = !this.usersService.isLoggedIn();
	}

	logContactUs(){
		this.submitted = true;

		this.form.subject = this.form.subject.trim();
		this.form.body = this.form.body.trim();

		if(!this.form.subject || !this.form.body)
			return this.notificationMessage.setMessage(this.translateService.instant('g.invalid_input'), {clearOnXTimeNavigate: 1});

		if(this.userId)
			this.form.userId = this.userId;

		let captchaData: any = {};
		if(this.captchaEnabled) {
			captchaData = {
				key: this.captchaComponent.captchaKey,
				text: this.captchaComponent.captchaText
			};
		}

		this.usersService.logContactUs(this.form, captchaData.key, captchaData.text).subscribe(
			(data: any) => {
				if(data.response_status == this.loader.resStatus.invalid_captcha) {
					this.captchaComponent.getCaptcha();
				} else {
					this.router.navigate(['/']);
					this.notificationMessage.setMessage('translate|contact_us.thank_you_for_contacting_us', {type: 'success'});
				}
			}
		);
	}

	goBack() {
		if(this.options.forced || this.navigation.getPreviousUrl() === '/landing')
			return this.router.navigate(['/']);

		return this.navigation.goBack('/');
	}
}
